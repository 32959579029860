import React, { useEffect, useState } from "react";
import Slider from "../components/carousel/Slider";
import Slider1 from "../components/carousel/Slider1";
import "../components/component.css";
import "../components/font.css";
import { FaRegHeart } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa";
import { AiOutlineShareAlt } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import socket from "../utils/socket";
import Banner from "../components/banner/banner";
import "../Font.css";

const Product = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { slug } = useParams();

  const navigate = useNavigate();
  const [changimg, setchangimg] = useState(0)
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [imgs, setimgs] = useState([])

  useEffect(() => {
    setProgress(50);
    const handleProduct = async () => {
      try {
        if (localStorage.getItem("just_free_token")) {
          console.log("in===============>>>>");
          const res = await axios(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_PRODUCT_DETAILS_AUTH}/${slug}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${JSON.parse(
                  localStorage.getItem("just_free_token")
                )}`,
                "Content-Type": "application/json",
              },
            }
          );
          if (res.status === 200) {
            console.log(res.data.products, "===================.....");
            setProgress(100);
            setData(res.data.products);
            setimgs(res.data.products[0].product_image)
          }
        } else {
          await axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_PRODUCT_DETAILS}/${slug}`
            )
            .then((res) => {
              setData(res.data.products);
              setProgress(100);
            })
            .catch((error) => {
              if (error.response) {
                // Handle errors with a response (e.g., server errors)
                console.log("Data :", error.response.data);
                toast.warning(error.response.data.message);
                console.log("Status :" + error.response.status);
                setProgress(100);
              } else if (error.request) {
                // Handle errors without a response (e.g., network errors)
                console.log("No response received.");
                toast.error("No response received.");
                setProgress(100);
              } else {
                // Handle other errors (e.g., setup errors)
                console.log("Error", error.message);
                toast.error(error.message);
                setProgress(100);
              }
            });
        }
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    handleProduct();
  }, [slug]);

  //socket
  const [getroomId, setGetRoomId] = useState();

  const [roomId, setroomId] = useState("");

  const [userName, setUserName] = useState(null);
  const [userId, setuserId] = useState(null);

  if (data && data.length > 0) {
    let other_User = data.map((item) => item.user);
    var otherUser = other_User[0][0].user_name;
  }

  if (data && data.length > 0) {
    let other_User_id = data.map((item) => item.user);
    var otherUserId = other_User_id[0][0].unique_id;
  }

  if (data && data.length > 0) {
    let product_Id = data.map((item) => item.product_uniqueid);
    var productID = product_Id[0];
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("LoginUser");
    const storedUserId = localStorage.getItem("userId");
    if (storedUser) {
      // Parse the JSON string from localStorage into an object
      const parsedUser = storedUser;
      setUserName(parsedUser);
      setuserId(storedUserId);
    }
  }, []);

  console.log(userName);
  console.log("eeee", otherUser, localStorage.getItem("userId"));

  const handleJoinRoom = async () => {
    var receivedRoomId; // Declare receivedRoomId in a higher scope
    if (data[0].user[0] && data[0].user[0].unique_id == localStorage.getItem("userId")) {
      toast.warning("You cannot make an offer on your own product")
    } else {
      try {
        socket.emit("join_room", { userName: userName, authorUserId: userId });
        socket.on("roomId", async (data) => {
          // Declare the callback function as async
          try {
            receivedRoomId = JSON.parse(data); // Assign the value to receivedRoomId

            // Ensure receivedRoomId is not empty and otherUser is defined before emitting
            if (receivedRoomId && otherUser) {
              socket.emit("join_room", {
                otherUser: otherUser,
                roomId: receivedRoomId,
                otherUserId: otherUserId,
              });
              // Move the Axios request here
              try {
                console.log(
                  "Received room ID:",
                  receivedRoomId,
                  otherUser,
                  productID
                );
                const response = await axios(
                  "https://justfree.webinatech.com/admin/public/api/get-chat-data",
                  {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem("just_free_token")
                      )}`,
                      "Content-Type": "application/json",
                    },
                    data: {
                      room_id: receivedRoomId,
                      user2_id: otherUserId,
                      product_id: productID, // Make sure 'productID' is defined as well
                    },
                  }
                );

                if (response.status === 200) {
                  console.log(response.data.message);
                  navigate("/chat");
                } else {
                  console.error(
                    "API request failed with status code:",
                    response.status
                  );
                  console.error("API response data:", response.data);
                }
              } catch (error) {
                console.error("Error occurred during API request:", error);
              }
            } else {
              console.error("Received room ID or otherUser is not defined.");
            }
          } catch (error) {
            console.error("Error parsing room ID:", error);
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  const handleAddProduct = async (id) => {
    setProgress(50);
    try {
      await axios(
        process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_WISHLIST,
        {
          method: "POST",
          data: {
            product_id: id,
          },

          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("just_free_token")
            )}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          setProgress(100);
          toast.success(res.data.message);
        })
        .catch((error) => {
          if (error.response) {
            // Handle errors with a response (e.g., server errors)
            console.log("Data :", error.response.data);
            toast.warning(error.response.data.message);
            console.log("Status :" + error.response.status);
            setProgress(100);
          } else if (error.request) {
            // Handle errors without a response (e.g., network errors)
            console.log("No response received.");
            toast.error("No response received.");
            setProgress(100);
          } else {
            // Handle other errors (e.g., setup errors)
            console.log("Error", error.message);
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      // Get the current URL
      const currentUrl = window.location.href;

      // Copy the URL to the clipboard
      await navigator.clipboard.writeText(currentUrl);

      // Update state to indicate that the URL has been copied
      setIsCopied(true);
      toast.success("copy clipboard");
      // Reset the copied state after a brief delay
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error("Failed to copy URL to clipboard", error);
    }
  };

  console.log(userId, "===========>", data);
  // useEffect(() => {
  //   if (data.length > 0) {
  //     const ifameData = document.getElementById("iframeId");
  //     const lat = data[0].latitude;
  //     const lon = data[0].longitude;
  //     ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
  //   }
  // }, [data]);



  const hendleChangImg = (index) => {
    setchangimg(index)
  }

  console.log(imgs,"{}}}}}}}}}}}}")

  return (
    <section className="overflow-hidden bg-white">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="mx-auto max-w-5xl px-2 py-24">
        <div className="mx-auto flex flex-wrap items-center">
          <div className="">
            {data.length !== 0 ? (
              <div>
                <img
                  alt="Nike Air Max 21A"
                  className="w-full rounded object-cover mb-4 product-img"
                  src={imgs && imgs[changimg] && imgs[changimg].image ? imgs[changimg].image : ""}

                />

                <div className="mt-2 w-full lg:order-1 lg:flex-shrink-0">
                  <div className="flex flex-row items-start lg:flex-col" style={{ width: 'calc(100% - 53px)', overflow: 'auto' }}>
                    {imgs && Array.isArray(imgs) && imgs.map((item, index) =>
                      <button
                        key={index}
                        onClick={() => hendleChangImg(index)}
                        type="button"
                        className={`flex-0 aspect-square mb-3 h-20 w-28 overflow-hidden rounded-lg ${index == changimg ? "border-2 border-gray-900  " : ""} text-center`}
                        style={{ width: '80px', height: '80px', marginLeft: "2px", marginRight: "2px" }}
                      >
                        <img className="h-full w-full object-cover" src={item.image} alt="" />
                      </button>
                    )}

                  </div>
                </div>

              </div>

            ) : (
              <p></p>
            )}
            {/* <div className="">
              </div> */}

            <div className="items-center border-t-2 border-gray-400 mb-0">
              <h1 className="user-title pt-2">Description</h1>
              {data && data.length > 0 && (
                <p className="user-p">
                  {data[0].product_description}
                </p>
              )}

            </div>

            <div className="flex items-center border-b-2 border-gray-400 pb-3"></div>
          </div>

          <div className="mt-6 w-full lg:mt-0 lg:w-1/2 lg:pl-32">
            {data.length !== 0 ? (
              <div>
                <h1 className="my-4 text-4xl font-bold text-black montserrat-regular alegreya-unique-class">
                  {data[0].product_name}
                </h1>
                <div className="mt-3 flex items-center">
                  <div className="text-heading price-sec pr-2 text-base font-bold md:pr-0 md:text-xl lg:pr-2 lg:text-2xl 2xl:pr-0 2xl:text-4xl">
                    ₹ {data[0].product_price}
                  </div>
                </div>
                <p className="leading-relaxed">
                  Posted {data[0].time} in {data[0].city}
                </p>
                <p className="leading-relaxed">
                  Condition: {data[0].product_condition}
                </p>
              </div>
            ) : (
              <div></div>
            )}
            <div className="offerandadd-button">
              <a
                onClick={handleJoinRoom}
                className="inline-flex items-center justify-center btn-style-bg px-10 py-0 text-xl font-semibold text-center text-gray-900 no-underline align-middle transition-all duration-300 ease-in-out  border-2 border-[#Fa8128] border-solid rounded-full cursor-pointer select-none hover:text-gray-600 hover:border-[#Fa8128] focus:shadow-xs focus:no-underline"
              >
                Make Offer
              </a>
              <button
                className="inline-flex items-center justify-center btn-style px-10  py-0 text-xl font-semibold text-center text-gray-900 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-[#Fa8128] border-solid rounded-full cursor-pointer select-none hover:text-gray-600 hover:border-[#Fa8128] focus:shadow-xs focus:no-underline"
                onClick={handleJoinRoom}
              >
                Add
              </button>
            </div>
            <div className="mt-4 wishlist-sec">
              <div
                className="wishlist-sec-left cursor-pointer"
                onClick={() => handleAddProduct(data[0].product_id)}
              >
                {data.length > 0 && data[0].wishlist_status === false ? (
                  <FaRegHeart className="mr-40 ml-4 float-left" size={20} />
                ) : (
                  <FaHeart
                    className="mr-40 ml-4 float-left text-orange-500"
                    size={20}
                  />
                )}

                {/* <AiOutlineHeart className="mr-40 ml-4 float-left" size={20} /> */}
                <p> Save </p>
              </div>
              <div
                className="wishlist-sec-right cursor-pointer"
                onClick={handleCopyClick}
              >
                <AiOutlineShareAlt scale={20} />
                <p>{isCopied ? "Copied!" : " Share"}</p>
              </div>
            </div>
            <div className="mb-4 mt-4 flex items-center border-b-2 border-gray-400 pb-3"></div>
            <div className="d-flex user-sty">
              <FaUserCircle size={30} />
              {data.length !== 0 ? (
                <div>
                  <h1 className="user-title">{data[0].user[0].user_name}</h1>
                  <p className="user-p">{data[0].user[0].user_create_time}</p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="flex items-center border-t-2 border-gray-400 pb-3"></div>
            <div className="flex items-center justify-between"></div>
          </div>
        </div>
      </div>
      {/* <iframe  id="iframeId" ></iframe> */}
      <div className=" ifmap">
      <Banner />

      </div>
      <div className="line"></div>
      {data.length !== 0 ? (
        <div>
          <Slider id={data[0].product_id} />
          <Slider1 id={data[0].product_id} />
        </div>
      ) : (
        <div></div>
      )}

    </section>
  );
};

export default Product;
