import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import "../../App.css";
import "../../Font.css";
import { useDispatch } from "react-redux";
import { productByDistance } from "../../redux/productAction";
import { useSelector } from "react-redux";
import { TbMapPinFilled } from "react-icons/tb";
const Homeproducts = ({ latitude, longitude }) => {
  const data = useSelector((state) => state.productDistanceData);
  const dispatch = useDispatch();
  // const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   setProgress(50);
  //   const handleProduct = async () => {
  //     try {
  //       await axios
  //         .get(
  //           process.env.REACT_APP_API_BASE_URL +
  //           process.env.REACT_APP_API_PRODUCTS
  //         )
  //         .then((res) => {
  //           setData(res.data.products);
  //           setProgress(100);
  //         })
  //         .catch((error) => {
  //           if (error.response) {
  //             // Handle errors with a response (e.g., server errors)
  //             console.log("Data :", error.response.data);
  //             toast.warning(error.response.data.message);
  //             console.log("Status :" + error.response.status);
  //             setProgress(100);
  //           } else if (error.request) {
  //             // Handle errors without a response (e.g., network errors)
  //             console.log("No response received.");
  //             toast.error("No response received.");
  //             setProgress(100);
  //           } else {
  //             // Handle other errors (e.g., setup errors)
  //             console.log("Error", error.message);
  //             toast.error(error.message);
  //             setProgress(100);
  //           }
  //         });
  //     } catch (err) {
  //       toast.error("Something Wrong");
  //     }
  //   };
  //   handleProduct();
  // }, []);

  useEffect(() => {
    dispatch(productByDistance(latitude, longitude, "5", "null", "null"));
  }, [dispatch, latitude, longitude]);

  console.log(data, "ppppppppppppp===========>>>");

  return (
    <div className="home-sec bg-white">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="mx-auto grid w-full max-w-7xl items-center space-y-4 lg:py-10 p b-5 sm:grid-cols-2 md:gap-6 md:space-y-0 lg:grid-cols-4">
        {data.map((item) => (
          <div
            key={item.product_id}
            className="rounded-md border  position-relative"
          >
            <Link to={`/product/${item.product_uniqueid}`}>
              {item.product_image[0]?.image ? (
                <img style={{objectFit: 'contain'}}
                  src={item.product_image[0].image}
                  alt="Laptop"
                  className="h-44 aspect-[16/9] w-full rounded-md md:aspect-auto"
                />
              ) : (
                ""
              )}
            </Link>

            <div className="p-4">
              <Link to={`/product/${item.product_uniqueid}`}>
                <div className="offce-and-product-status">
                  {/* <div className="minte">
                  
                </div> */}
                  {item.product_condition !== null ? (
                    <div className="item">{item.product_condition}</div>
                  ) : (
                    ""
                  )}
                </div>
                <h1 className="inline-flex items-center text-lg font-bold text-black alegreya-unique-class">
                  {item.product_name}
                </h1>
                <div className="mt-2 flex items-center space-x-2">
                  <span className="block text-sm font-semibold text-black">
                    ₹ {item.product_price}{" "}
                  </span>
                </div>
                <div className="mt-1 flex items-center space-x-2">
                  <i className="fa-regular fa-clock text-black"></i>
                  <h6 className=" mt-2 text-sm text-black ">{item.time}</h6>
                </div>
                <div className="mt-2 flex items-center space-x-2">
                  <TbMapPinFilled className="flex text-orange-500" />
                  <span className="block text-xs font-medium text-black">
                    {item.city}, {item.country}{" "}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Homeproducts;
