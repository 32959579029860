import axios from "axios";
import { takeEvery, put, takeLatest } from "redux-saga/effects";
import {
  Category_Product,
  Condition_Product,
  Set_Product_List,
  Set_Distance_Product,
  Get_Distance_Product
} from "../redux/constant";

function* productsByCategory(data) {
  console.log("saga", data.id);
  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_BASE_URL +
        process.env.REACT_APP_API_CATEGORIES_PRODUCT,
      {
        category_id: data.id,
      }
    );
    yield put({ type: Set_Product_List, data: res.data.products });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* productCondition(data) {
  console.log("saga", data.query);
  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_BASE_URL +
        process.env.REACT_APP_API_CONDITIONS_PRODUCT,
      {
        condition_name: data.query,
      }
    );
    yield put({ type: Set_Product_List, data: res.data.products });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* productDistance(data) {
  console.log("saga", data.latitude,data.longitude,data.distance,data.search,data.condition);
  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_BASE_URL +
        process.env.REACT_APP_API_DISTANCE,
      {
        latitude: data.latitude,
        longitude: data.longitude,
        distance: data.distance,
        search: data.search,
        condition: data.condition,
      }
    );
    console.log(res.data.products,"=======>>>")
    yield put({ type: Set_Distance_Product, data: res.data.products });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* productSaga() {
  yield takeLatest(Condition_Product, productCondition);
  yield takeEvery(Category_Product, productsByCategory);
  yield takeEvery(Get_Distance_Product, productDistance);
}

export default productSaga;
