import React, { useState, useEffect } from "react";
// import { AiOutlineSend } from "react-icons/ai";
// import { AiOutlinePaperClip } from "react-icons/ai";
import socket from "../utils/socket";
import ScrollToBottom from "react-scroll-to-bottom";
const Chat = () => {
  const [userId, setuserId] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [chatRoomId, setchatRoomId] = useState(null)
  const [chatUserName, setchatUserName] = useState(null)
  const [chatImg, setchatImg] = useState(0)

  // Check if userId is defined and not an empty array


  async function fetchChatData() {
    try {
      const formdata = new FormData();
      formdata.append("user_unique_id", localStorage.getItem("userId"));

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        "https://justfree.webinatech.com/admin/public/api/chat-data",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setuserId(result.chat_data);

      console.log(result.chat_data && result.chat_data.length)

      if (result.chat_data && result.chat_data.length > 0) {
        console.log("Room ID:", result.chat_data[0]?.room_id);

        try {
          const messagesResponse = await fetch(
            `http://localhost:3001/api/rooms/${result.chat_data[0].room_id}/messages`
          );

          if (!messagesResponse.ok) {
            throw new Error(`HTTP error! Status: ${messagesResponse.status}`);
          }

          const messagesResult = await messagesResponse.json();
          console.log("Messages data:", messagesResult);
          setMessageList(messagesResult.data);
        } catch (error) {
          console.error("Error fetching messages data:", error);
          throw error; // Re-throw the error to handle it elsewhere if needed
        }
      } else {
        console.error("Chat data does not have enough elements.");
      }

      console.log(result);
    } catch (error) {
      console.error("Error fetching chat data:", error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  }

  useEffect(() => {

    fetchChatData();
  }, []);

  if (userId && userId.length > 0) {
    // Check if userId is already set to avoid unnecessary state updates
    console.log(chatRoomId === null, "=>>>>>>>>>>>>>><<<<<<<<<")
    if (chatRoomId === null) {
      if (chatRoomId !== userId[0].room_id) {
        setchatRoomId(userId[0].room_id);
      }
      if (chatUserName !== userId[0].user_one_name) {
        setchatUserName(userId[0].user_one_name);
      }

    } else {
      console.log("not call===================<<<<<<<<<<<");
    }
  } else {
    // Handle the case where userId is undefined or an empty array
    console.error("userId is undefined or empty");
  }

  // var roomId = userId[0].room_id;
  // var username = userId[0].user_one_name;
  // var roomId
  // var username
  const celectUser = async (roomId, userName, index) => {
    console.log("yyyygfgffgvvcf", roomId, userName)
    if (userId && userId.length > 0) {
      try {
        const messagesResponse = await fetch(
          `http://localhost:3001/api/rooms/${roomId}/messages`
        );

        if (!messagesResponse.ok) {
          throw new Error(`HTTP error! Status: ${messagesResponse.status}`);
        }

        const messagesResult = await messagesResponse.json();
        console.log("Messages data:", messagesResult);
        setMessageList(messagesResult.data);
      } catch (error) {
        console.error("Error fetching messages data:", error);
        throw error; // Re-throw the error to handle it elsewhere if needed
      }
      setchatRoomId(roomId)
      setchatUserName(userName)
      setchatImg(index)
      // Use roomId and username as needed
    } else {
      // Handle the case where userId is undefined or an empty array
      console.error("userId is undefined or empty");
    }
  }
  console.log("yyyygfgffgvvcf=>>>>>>>>>>><<<<<<<<<<<<<<<<<<", chatRoomId, chatUserName)


  const sendMessage = async () => {
    if (currentMessage !== "") {
      const messageData = {
        roomId: chatRoomId,
        author: chatUserName,
        message: currentMessage,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };

      console.log(messageData);

      // Check if the message already exists in messageList
      const isDuplicate = messageList.some((message) => {
        return (
          message.room === messageData.room &&
          message.author === messageData.author &&
          message.message === messageData.message &&
          message.time === messageData.time
        );
      });

      if (!isDuplicate) {
        await socket.emit("send_message", messageData);
      }

      setCurrentMessage("");
    }
  };

  useEffect(() => {
    // Function to handle incoming messages
    const handleReceiveMessage = (data) => {
      console.log("Received message:>>>>>>>>>>>>>", data);

      // Check if the message already exists in messageList
      const isDuplicate = messageList.some((message) => {
        return (
          message.room === data.room &&
          message.author === data.author &&
          message.message === data.message &&
          message.time === data.time
        );
      });

      if (!isDuplicate) {
        setMessageList((list) => [...list, data]);
      }
    };

    // Add the event listener
    socket.on("receive_message", handleReceiveMessage);

    // Remove the event listener when the component unmounts
    return () => {
      socket.off("receive_message", handleReceiveMessage);
    };
  }, [messageList]);

  console.log("hgbcaujcgajcaj", messageList);


  return (
    <div className="flex h-screen antialiased text-gray-800">
      <div className="flex flex-row h-full w-full overflow-x-hidden">
        <div className="flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0">
          <div className="flex flex-col">
            <div className="flex flex-col space-y-1 mt-4 -mx-2 h-full overflow-y-auto cursor-pointer">
              {userId.map((items, index) => (
                <ul
                  className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                  key={items.user_two_id}
                  onClick={() => celectUser(items.room_id, items.user_one_name, index)}
                >
                  <li className="flex items-center" key={index}>
                    <div>
                      {items.user_two_image && (
                        <img
                          className="w-10 h-10 rounded-full"
                          src={`https://justfree.webinatech.com/admin/public/profile_image/${items.user_two_image}`}
                          alt={`Profile of ${items.user_two_name}`}
                        />
                      )}
                    </div>
                    <div className="ml-2 text-sm font-semibold">
                      {items.user_two_name}
                    </div>
                  </li>
                </ul>
              ))}


            </div>
          </div>
        </div>
        <div className="flex flex-col flex-auto h-full p-2">
          <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-white h-full p-2">
            <div className="py-1 px-3 bg-grey-lighter flex flex-row justify-between items-center shadow-md">
              <div className="flex items-center">
                <div>
                  {userId && userId.length > 0 && userId[0].user_one_image && (
                    <img
                      className="w-10 h-10 rounded-full"
                      src={`https://justfree.webinatech.com/admin/public/profile_image/${userId[chatImg].user_two_image}`}
                      alt="User One Image"
                    />
                  )}
                </div>

                <div className="ml-4">
                  {userId && userId.length > 0 && userId[chatImg].user_one_image && (
                    <p className="text-grey-darkest">
                      {userId[chatImg].user_two_name}
                    </p>
                  )}
                  <p className="text-grey-darker text-xs mt-1">
                    Andrés, Tom, Harrison, Arnold, Sylvester
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col h-full overflow-x-auto mb-4">
              <div className="flex flex-col h-full">
                <ScrollToBottom>
                  <div className="grid grid-cols-12 gap-y-2">
                    {messageList &&
                      messageList.map((messageContent, index) =>
                        chatUserName === messageContent.author ? (
                          <div
                            key={index}
                            className="col-start-6 col-end-13 p-3 rounded-lg"
                          >
                            <div className="flex items-center justify-start flex-row-reverse">
                              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                                {userId &&
                                  userId.length > 0 &&
                                  userId[0].user_one_image && (
                                    <img
                                      className="w-10 h-10 rounded-full"
                                      src={`https://justfree.webinatech.com/admin/public/profile_image/${userId[chatImg].user_one_image}`}
                                      alt="User One Image"
                                    />
                                  )}
                              </div>
                              <div className="relative mr-3 text-sm bg-orange-100 py-2 px-4 shadow rounded-xl">
                                <div>{messageContent.message}</div>
                                {/* <h6 className=" text-xs">{messageContent.time}</h6> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="col-start-1 col-end-8 p-3 rounded-lg"
                          >
                            <div className="flex flex-row items-center">
                              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                                {userId &&
                                  userId.length > 0 &&
                                  userId[0].user_one_image && (
                                    <img
                                      className="w-10 h-10 rounded-full"
                                      src={`https://justfree.webinatech.com/admin/public/profile_image/${userId[chatImg].user_two_image}`}
                                      alt="User One Image"
                                    />
                                  )}
                              </div>
                              <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                                <div>{messageContent.message}</div>
                                {/* <h6 className=" text-xs">{messageContent.time}</h6> */}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </ScrollToBottom>
              </div>
            </div>

            <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
              <div>
                <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="flex-grow ml-4">
                <div className="relative w-full">
                  <input
                    className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                    type="text"
                    value={currentMessage}
                    placeholder="Hey..."
                    onChange={(event) => {
                      setCurrentMessage(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                  {/* <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </button> */}
                </div>
              </div>
              <div className="ml-4">
                <button
                  onClick={sendMessage}
                  className="flex items-center justify-center bg-orange-500 hover:bg-orange-700 rounded-xl text-white px-4 py-1 flex-shrink-0"
                >
                  <span>Send</span>
                  <span className="ml-2">
                    <svg
                      className="w-4 h-4 transform rotate-45 -mt-px"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
