import React, { useEffect, useState } from "react";
import Homeproducts from "../components/homeproducts/Homeproducts";
import Banner from "../components/banner/banner";
import axios from "axios";
import { toast } from "react-custom-alert";

const Home = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            setError(error.message);
            // Show warning if user denies location access
            toast.warning("Please allow your location");
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };
  
    getLocation();
  }, []);
  

  useEffect(() => {
    const userToken = localStorage.getItem("just_free_token");

    if (userToken) {
      const seveUserNameLocal = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_DETAILS}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("just_free_token")
              )}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const userName = response.data?.data?.name;
          const userId = response.data?.data?.unique_id;
          localStorage.setItem("LoginUser", userName);
          localStorage.setItem("userId", userId);
        }
      };

      seveUserNameLocal();
    } else {
      // Token doesn't exist in localStorage
      // Handle this condition, e.g., show a message to the user
      toast.warning("Please log in to access this feature.");
    }
  }, []);


  useEffect(() => {

    const handleLocation = async () => {
      try {
        await axios
          .post(
            "https://justfree.webinatech.com/admin/public/api/get-pincode",
            {
              latitude: latitude,
              longitude: longitude,
            }
          )
          .then((res) => {
            console.log("_______pin", res.data.pincode);
            localStorage.setItem("Pin", res.data.pincode);
          })
          .catch((error) => {});
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    handleLocation();
  }, [latitude, longitude]);

  console.log(latitude, longitude);

  localStorage.setItem("latitude", latitude);
  localStorage.setItem("longitude", longitude);


  return (
    <div className="bg-white">
      <Banner />
      <Homeproducts latitude={latitude} longitude={longitude} />
    </div>
  );
};

export default Home;
