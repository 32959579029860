import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Dropdown from "react-bootstrap/Dropdown";
import "../component.css";
import { Form, Link, useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import "../login.css";
import "../../Font.css";
// import backgroundImageAlt from "../../image/altproduct.png";
import { BiUserCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import {
  productByCategory,
  productByDistance,
} from "../../redux/productAction";
import { IoMdArrowDropdown } from "react-icons/io";
// import styles from "./Modal.module.css";
// import { RiCloseLine } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import locationimg from "../../../src/image/location.png";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import OtpInput from "react-otp-input";
import { TbMapPinFilled } from "react-icons/tb";
import { FaTimes } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import logo from "../../image/mrfreee (2).png";

const Navebar = () => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showLogin, setshowLogin] = useState();
  const [showlist, setShowlist] = useState(false);
  // const [conditions, setconditions] = useState(false);

  // const [productName, setproductName] = useState();
  // const [productPrice, setproductPrice] = useState();

  // const [imgUpload, setImgUpload] = useState(null);
  // const [backgroundImage, setBackgroundImage] = useState(null);

  const [progress, setProgress] = useState(0);

  //REGISTER
  const [signUpName, setsignUpName] = useState();
  const [signeUpEmail, setsigneUpEmail] = useState();
  const [signeUpPassword, setsigneUpPassword] = useState();

  //Login
  const [loginEmail, setloginEmail] = useState();
  const [loginPassword, setloginPassword] = useState();

  //categories
  const [categoryList, setCategorylist] = useState([]);
  const [subCategory, setsubCategory] = useState([]);
  const [categorybysub, setcategorybysub] = useState([]);

  //add product

  // const [title, settitle] = useState(null);
  // const [price, setprice] = useState(null);
  // const [productd, setproductd] = useState(null);
  //const [productcategory, setproductcategory] = useState()

  const [conditionsGet, setconditionsGet] = useState(null);

  const [otpin, setotpin] = useState(false);
  const [otp, setOtp] = useState();
  const [distance, setdistance] = useState(5);

  const [nav, setnav] = useState(false);

  function valuetext(value) {
    console.log(value, "====================>>>>>>>>>>>");
    setdistance(value);
    return `${value}°C`;
  }
  const [hendleCondsan, sethendleCondsan] = useState();
  const hendleGetProduct = (e) => {
    const customValue = e.currentTarget.getAttribute("data-custom-value");
    setShowlist(!showlist);
    console.log("Custom Value:", customValue);
    dispatch(
      productByDistance(
        localStorage.getItem("latitude"),
        localStorage.getItem("longitude"),
        distance,
        "null",
        customValue // Assuming this is the correct function name
      )
    );
    sethendleCondsan(customValue);
    setconditionsGet(customValue);
  };
  const hendleGetProductm = (e) => {
    const customValue = e.currentTarget.getAttribute("data-custom-value");
    setShowlist(!showlist);
    console.log("Custom Value:", customValue);
    dispatch(
      productByDistance(
        localStorage.getItem("latitude"),
        localStorage.getItem("longitude"),
        distance,
        "null",
        customValue // Assuming this is the correct function name
      )
    );
    sethendleCondsan(customValue);
    setconditionsGet(customValue);
    setnav(!nav);
  };

  const handleSignUp = async (e) => {
    e.preventDefault(); // Move this line outside of the try-catch block

    try {
      setProgress(10);
      console.log(signUpName);
      console.log(signeUpEmail);
      console.log(signeUpPassword);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_REGISTER}`,
        {
          name: signUpName,
          email: signeUpEmail,
          password: signeUpPassword,
        }
      );

      setProgress(75);
      toast.success(response.data.message, "OTP Verification");
      setshowLogin(!showLogin);
      setotpin(!otpin);

      // Make a separate API call for OTP verification
      const otpResponse = await axios.post(
        "https://justfree.webinatech.com/admin/public/api/register-otp",
        {
          name: signUpName,
          email: signeUpEmail,
        }
      );

      setProgress(100);
      toast.success(otpResponse.data.message);
    } catch (error) {
      if (error.response) {
        // Handle errors with status codes
        console.log("Data:", error.response.data);
        toast.warning(error.response.data.message);
      } else if (error.request) {
        // Handle errors with the request itself
        console.log(error.request);
        toast.error("No response received from the server");
      } else {
        // Handle other types of errors
        console.log("Error:", error.message);
        toast.error("An error occurred while processing your request");
      }
    } finally {
      setProgress(100); // Set progress to 100 in the finally block to ensure it's always updated
    }
  };

  const handleLogin = async (e) => {
    setProgress(10);
    console.log(loginEmail);
    console.log(loginPassword);
    try {
      e.preventDefault();
      setProgress(75);
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_LOGIN,
          {
            email: loginEmail,
            password: loginPassword,
          }
        )
        .then((res) => {
          setProgress(100);
          localStorage.setItem(
            "just_free_token",
            JSON.stringify(res.data.token)
          );
          toast.success(res.data.message);
          setshowLogin(!showLogin);
        })
        .catch((error) => {
          if (error.response) {
            // status code out of the range of 2xx
            console.log("Data :", error.response.data);
            toast.warning(error.response.data.message);
            console.log("Status :" + error.response.status);
            setProgress(100);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            toast.error(error.request);
            setProgress(100);
          } else {
            // Error on setting up the request
            console.log("Error", error.message);
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  useEffect(() => {
    const handleCategories = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
              process.env.REACT_APP_API_CATEGORIES
          )
          .then((res) => {
            setCategorylist(res.data.data);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    const handleSubCategories = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
              process.env.REACT_APP_API_SUBCATEGORY
          )
          .then((res) => {
            setsubCategory(res.data.data);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };
    handleCategories();
    handleSubCategories();
  }, []);

  console.log("yyyyyyyyy>", subCategory);

  const handleFilterCategories = (id) => {
    setProgress(50);

    dispatch(productByCategory(id));
    navigate("/products");
    setProgress(100);

    console.log("usuusususus", id);
  };
  const [isOpen, setIsOpen] = useState(false);

  const hendlAddProduct = () => {
    if (localStorage.getItem("just_free_token") === null) {
      toast.warning("please login");
      setshowLogin(!showLogin);
    } else {
      navigate("/addproduct");
    }
  };

  const hendleForgot = () => {
    setshowLogin(!showLogin);

    // setTimeout(() => {
    // }, 1000);
    setIsOpen(!isOpen);
  };

  const [pin, setpin] = useState(localStorage.getItem("Pin"));

  const [data, setData] = useState([]);

  useEffect(() => {
    setProgress(50);

    // Check if the token is present in localStorage
    const userToken = localStorage.getItem("just_free_token");

    if (userToken) {
      const handleUserData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_DETAILS}`,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(userToken)}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log(response.data.data);
          setData(response.data.data);
          setProgress(100);
        } catch (error) {
          if (error.response) {
            // Handle errors with a response (e.g., server errors)
            console.log("Data :", error.response.data);
            toast.warning(error.response.data.message);
            console.log("Status :" + error.response.status);
            setProgress(100);
          } else if (error.request) {
            // Handle errors without a response (e.g., network errors)
            console.log("No response received.");
            toast.error("No response received.");
            setProgress(100);
          } else {
            // Handle other errors (e.g., setup errors)
            console.log("Error", error.message);
            toast.error(error.message);
            setProgress(100);
          }
        }
      };

      handleUserData();
    } else {
      toast.warning("Please log in to access this feature.");
      setProgress(100);
    }
  }, []);

  console.log(data);

  // const [showLoction, setshowLoction] = useState(false);

  const handleOtp = async () => {
    setProgress(50);
    try {
      const otpResponse = await axios.post(
        "https://justfree.webinatech.com/admin/public/api/register-otp-verify",
        {
          otp: otp,
          email: signeUpEmail,
        }
      );

      if (otpResponse.status === 200) {
        // Check the status property of otpResponse
        setotpin(!otpin); // Update the state based on the status code
        toast.success(otpResponse.data.message);
        setProgress(100);
      } else {
        // Handle other status codes if needed
        toast.error("Failed to verify OTP");
      }
      if (otpResponse == 200) {
        setotpin(!otpin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hendleZipProduct = async () => {
    dispatch(
      productByDistance(
        localStorage.getItem("latitude"),
        localStorage.getItem("longitude"),
        distance,
        "null",
        "null"
      )
    );
    setShow(false);
    // try {
    //   const zipResponse = await axios.post(
    //     "https://justfree.webinatech.com/admin/public/api/distance",
    //     {
    //       latitude: localStorage.getItem("latitude"),
    //       longitude: localStorage.getItem("longitude"),
    //       distance: distance,
    //       search: "null",
    //       condition: "null",
    //     }
    //   );

    //   if (zipResponse.status === 200) {
    //     console.log(zipResponse.data.products, "================<<<<<<<<<<<<");
    //   } else {
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleSearch = async (e) => {
    dispatch(
      productByDistance(
        localStorage.getItem("latitude"),
        localStorage.getItem("longitude"),
        distance,
        e,
        hendleCondsan // Assuming this is the correct function name
      )
    );
    console.log(e, "========>>", hendleCondsan); // Assuming this is the correct function name
  };

  const hendleTopHeader = async (e, id) => {
    e.preventDefault();
    console.log(id);
    try {
      await axios
        .post("https://justfree.webinatech.com/admin/public/api/subcategory", {
          category_id: id,
        })
        .then((res) => {
          setcategorybysub(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            // Handle errors with a response (e.g., server errors)
            console.log("Data :", error.response.data);
            toast.warning(error.response.data.message);
            console.log("Status :" + error.response.status);
            setProgress(100);
          } else if (error.request) {
            // Handle errors without a response (e.g., network errors)
            console.log("No response received.");
            toast.error("No response received.");
            setProgress(100);
          } else {
            // Handle other errors (e.g., setup errors)
            console.log("Error", error.message);
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  return (
    <div>
      <div className="relative w-full bg-white navbar-top-sec">
        <div className="top-bar-sec mx-auto flex max-w-7xl items-center justify-between px-4 py-2 sm:px-6 lg:px-8">
          <div className="inline-flex items-center space-x-2">
            <Link to="/" className="cursor-pointer">
              <img src={logo} alt="" className="h-28 w-28" />
            </Link>
          </div>
          <div className="hidden grow items-start lg:flex align-items-center">
            <ul className="ml-12 inline-flex space-x-8 mb-0">
              <li className="main">
                <h2 className="dropdown-text mb-0">Dropdown with search box</h2>
                <select name="">
                  <option value="1">{pin}</option>
                  {/* <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="4">Four</option> */}
                </select>
              </li>
              <li>
                <div className="wrapper" style={{ transform: "inherit" }}>
                  <div className="search_box">
                    <div className="search_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e.target.value)}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handleSearch(event.target.value); // Pass e.target.value instead of event
                          }
                        }}
                      />
                      <i className="fas fa-search " />
                    </div>
                    <div
                      className="dropdown"
                      onClick={() => setShowlist(!showlist)}
                    >
                      <div className="default_option">
                        {conditionsGet !== null ? conditionsGet : "All"}
                      </div>
                    </div>
                    {showlist && (
                      <div
                        className="absolute right-0 z-10 mt-5 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabIndex={-1}
                      >
                        <div className="py-1" role="none">
                          <a
                            className="font-medium text-gray-900 block px-4 py-2 text-sm"
                            role="menuitem"
                            tabIndex={-1}
                            id="menu-item-0"
                            data-custom-value="Used"
                            onClick={(e) => hendleGetProduct(e)}
                          >
                            Used
                          </a>
                          <a
                            className="text-gray-900 block px-4 py-2 text-sm"
                            role="menuitem"
                            tabIndex={-1}
                            id="menu-item-1"
                            data-custom-value="New"
                            onClick={(e) => hendleGetProduct(e)}
                          >
                            New
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="">
            <ul className="ml-12 inline-flex items-center space-x-8 mb-1">
              {/* <li className="top-bar-menu-sec text-orange-500">
                <FaMapMarkerAlt
                  size={25}
                  onClick={() => setshowLoction(!showLoction)}
                />
              </li> */}
              <li>
                <div
                  variant="primary"
                  className="flex cursor-pointer mr-9 mt-3"
                  onClick={handleShow}
                >
                  <TbMapPinFilled className=" mt-1 text-orange-500" size={18} />
                  <h6 className="p-1">Location</h6>
                </div>
              </li>
              {localStorage.getItem("just_free_token") ? (
                <li className="top-bar-menu-sec hidden space-x-2 lg:block">
                  {" "}
                  <Link to="/Wishlist">
                    {" "}
                    <i className="fa-regular fa-heart"></i> <br></br> Saved{" "}
                  </Link>{" "}
                </li>
              ) : (
                ""
              )}
              {localStorage.getItem("just_free_token") ? (
                <li className="top-bar-menu-sec hidden space-x-2 lg:block">
                  {" "}
                  <Link to="/chat">
                    {" "}
                    <i className="fa-regular fa-message"></i> <br></br> Inbox{" "}
                  </Link>{" "}
                </li>
              ) : (
                ""
              )}
              {localStorage.getItem("just_free_token") ? (
                <li className="top-bar-menu-sec hidden space-x-2 lg:block">
                  {" "}
                  <Link to="/myproduct">
                    {" "}
                    <i className="fa-solid fa-tag"></i> <br></br> My items{" "}
                  </Link>{" "}
                </li>
              ) : (
                ""
              )}

              {/* <li className="top-bar-menu-sec"> <Link> <i className="fa-regular fa-heart"></i> <br></br> Saved </Link> </li> */}
              {localStorage.getItem("just_free_token") ? (
                ""
              ) : (
                <li className="top-bar-menu-sec1 hidden space-x-2 lg:block">
                  <Link
                    to="/about"
                    className="inline-flex items-center text-sm font-semibold text-[#Fa8128] hover:text-[#c55302]"
                  >
                    About
                  </Link>
                </li>
              )}
              {localStorage.getItem("just_free_token") ? (
                ""
              ) : (
                <li className="top-bar-menu-sec1 hidden space-x-2 lg:block">
                  <Link to="/help">
                    <button className="inline-flex items-center text-sm font-semibold text-[#Fa8128] hover:text-[#c55302]">
                      Help
                    </button>
                  </Link>
                </li>
              )}
              {/* <li>
                <button className="inline-flex items-center text-sm font-semibold text-[#Fa8128] hover:text-[#c55302]">
                  Add item
                </button>
              </li> */}

              {localStorage.getItem("just_free_token") ? (
                <li className="top-bar-menu-sec1 hidden space-x-2 lg:block">
                  {data ? (
                    <Link
                      to="/userprofile"
                      className="inline-flex items-center text-sm  font-semibold text-[#Fa8128] hover:text-[#c55302]"
                    >
                      <img
                        src={
                          data.image
                            ? `https://justfree.webinatech.com/admin/public/profile_image/${data.image}`
                            : "https://cdn-icons-png.flaticon.com/512/666/666201.png"
                        }
                        className="w-8 h-8 bg-gray-300 rounded-full mb-4"
                        alt="User Avatar"
                      />
                    </Link>
                  ) : (
                    <div>Loading...</div>
                  )}
                </li>
              ) : (
                <li className="top-bar-menu-sec1 hidden space-x-2 lg:block">
                  <button
                    onClick={() => setshowLogin(!showLogin)}
                    className="inline-flex items-center text-sm font-semibold text-[#Fa8128] hover:text-[#c55302]"
                  >
                    Login
                  </button>
                </li>
              )}
            </ul>
          </div>

          {/* {showLoction && (
            <div
              id="popup-modal"
              tabindex="-1"
              class=" overflow-y-auto  fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
              <div class="relative p-4 w-full max-w-md max-h-full ml-[800px] mt-[60px]">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div class=" md:p-5 text-center ml-[-20px]">
                    <div
                      className="relative w-screen  max-w-sm px-4 py-4 sm:px-5 lg:px-4"
                      aria-modal="true"
                      role="dialog"
                      tabIndex={-1}
                    >
                      <h4>Location </h4>
                      <button
                        className="absolute end-4 top-4 text-gray-600 transition hover:scale-110"
                        onClick={() => setshowLoction(!showLoction)}
                      >
                        <span className="sr-only">Close cart</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                      <h4>Delyvery Method </h4>
                      <div className="mt-2 space-y-6">
                        <ul className="space-y-4">
                          <li className="flex items-center gap-4">
                      
                            <div className="max-w-lg mx-auto">
                              <fieldset className="mb-5">
                                <legend className="sr-only">Countries</legend>
                                <div className="flex items-center mb-4">
                                  <input
                                    id="country-option-1"
                                    type="radio"
                                    name="countries"
                                    defaultValue="USA"
                                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                                    aria-labelledby="country-option-1"
                                    aria-describedby="country-option-1"
                                    defaultChecked=""
                                  />
                                  <label
                                    htmlFor="country-option-1"
                                    className="text-sm font-medium text-gray-900 ml-2 block"
                                  >
                                    United States
                                  </label>
                                </div>
                                <div className="flex items-center mb-4">
                                  <input
                                    id="country-option-2"
                                    type="radio"
                                    name="countries"
                                    defaultValue="Germany"
                                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                                    aria-labelledby="country-option-2"
                                    aria-describedby="country-option-2"
                                  />
                                  <label
                                    htmlFor="country-option-2"
                                    className="text-sm font-medium text-gray-900 ml-2 block"
                                  >
                                    Germany
                                  </label>
                                </div>
                                <div className="flex items-center mb-4">
                                  <input
                                    id="country-option-3"
                                    type="radio"
                                    name="countries"
                                    defaultValue="Spain"
                                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                                    aria-labelledby="country-option-3"
                                    aria-describedby="country-option-3"
                                  />
                                  <label
                                    htmlFor="country-option-3"
                                    className="text-sm font-medium text-gray-900 ml-2 block"
                                  >
                                    Spain
                                  </label>
                                </div>
                                <div className="flex items-center mb-4">
                                  <input
                                    id="country-option-4"
                                    type="radio"
                                    name="countries"
                                    defaultValue="United Kingdom"
                                    className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                                    aria-labelledby="country-option-4"
                                    aria-describedby="country-option-4"
                                  />
                                  <label
                                    htmlFor="country-option-4"
                                    className="text-sm font-medium text-gray-900 ml-2 block"
                                  >
                                    United Kingdom
                                  </label>
                                </div>
                              </fieldset>
                            </div>
                          </li>
                        </ul>
                        <h1>ZIP Code</h1>
                        <div className="space-y-4 text-center">
                          <a
                            href="#"
                            className="block rounded-full bg-orange-500 px-5 py-3 text-sm text-gray-100 transition hover:bg-orange-800"
                          >
                            Checkout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {showLogin && (
            <div
              className="relative z-50"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <LoadingBar
                color="#FF8000"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
              />
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-2 pb-4 pt-3 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="z-10 mx-auto max-w-7xl">
                          <div className="row justify-content-center">
                            <div className="col-12 text-center align-self-center py-2">
                              <div className="section pb-2 pt-1 pt-sm-2 text-center">
                                <div className="ml-[420px]">
                                  <button
                                    className="text-[#Fa8128]"
                                    onClick={() => setshowLogin(!showLogin)}
                                  >
                                    <MdCancel size={25} />
                                  </button>
                                </div>

                                <h6 className="mb-0 pb-3">
                                  <span>Log In </span>
                                  <span>Sign Up</span>
                                </h6>

                                <input
                                  className="checkbox d-none"
                                  type="checkbox"
                                  id="reg-log"
                                  name="reg-log"
                                />
                                <label for="reg-log"></label>
                                <div className="card-3d-wrap mx-auto">
                                  <div className="card-3d-wrapper">
                                    <div className="card-front">
                                      <div className="center-wrap">
                                        <div className="section text-center">
                                          <h4 className="mb-4 pb-3">Log In</h4>
                                          <div className="form-group">
                                            <input
                                              onChange={(e) =>
                                                setloginEmail(e.target.value)
                                              }
                                              type="email"
                                              name="logemail"
                                              className="form-style"
                                              placeholder="Your Email"
                                              id="logemail"
                                              autocomplete="off"
                                            />
                                            <i className="input-icon uil uil-at"></i>
                                          </div>
                                          <div className="form-group mt-2">
                                            <input
                                              onChange={(e) =>
                                                setloginPassword(e.target.value)
                                              }
                                              type="password"
                                              name="logpass"
                                              className="form-style"
                                              placeholder="Your Password"
                                              id="logpass"
                                              autocomplete="off"
                                            />
                                            <i className="input-icon uil uil-lock-alt"></i>
                                          </div>
                                          <button
                                            onClick={handleLogin}
                                            className="btn mt-4"
                                          >
                                            submit
                                          </button>
                                          <p className="mb-0 mt-4 text-center">
                                            <button
                                              className="link"
                                              onClick={hendleForgot}
                                            >
                                              Forgot your password?
                                            </button>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="card-back">
                                      <div className="center-wrap">
                                        <div className="section text-center">
                                          <h4 className="mb-4 pb-3">Sign Up</h4>
                                          <div className="form-group">
                                            <input
                                              onChange={(e) =>
                                                setsignUpName(e.target.value)
                                              }
                                              type="text"
                                              name="logname"
                                              className="form-style"
                                              placeholder="Your Full Name"
                                              id="logname"
                                              autocomplete="off"
                                            />
                                            <i className="input-icon uil uil-user"></i>
                                          </div>
                                          <div className="form-group mt-2">
                                            <input
                                              onChange={(e) =>
                                                setsigneUpEmail(e.target.value)
                                              }
                                              type="email"
                                              name="logemail"
                                              className="form-style"
                                              placeholder="Your Email"
                                              id="logemail"
                                              autocomplete="off"
                                            />
                                            <i className="input-icon uil uil-at"></i>
                                          </div>
                                          <div className="form-group mt-2">
                                            <input
                                              onChange={(e) =>
                                                setsigneUpPassword(
                                                  e.target.value
                                                )
                                              }
                                              type="password"
                                              name="logpass"
                                              className="form-style"
                                              placeholder="Your Password"
                                              id="logpass"
                                              autocomplete="off"
                                            />
                                            <i className="input-icon uil uil-lock-alt"></i>
                                          </div>
                                          <button
                                            className="btn mt-4"
                                            onClick={handleSignUp}
                                          >
                                            submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isOpen && (
            <div
              className="relative z-1000"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              <div className="fixed inset-0 z-1000 w-screen overflow-y-auto">
                <div className="min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 sm:flex">
                  <div
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                    style={{ height: "400px" }}
                  >
                    {/* <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <h3
                            className="text-base font-semibold leading-6 text-gray-900"
                            id="modal-title"
                          >
                            Deactivate account
                          </h3>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to deactivate your account?
                              All of your data will be permanently removed. This
                              action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="card-3d-wrapper">
                      <div className="card-front">
                        <div className="center-wrap">
                          <div className="section text-center">
                            <h4 className="mb-4 pb-3">Forgot Password</h4>
                            <div className="form-group">
                              <input
                                onChange={(e) => setloginEmail(e.target.value)}
                                type="email"
                                name="logemail"
                                className="form-style"
                                placeholder="Your Email"
                                id="logemail"
                                autocomplete="off"
                              />
                              <i className="input-icon uil uil-at"></i>
                            </div>
                            <div className="form-group mt-2">
                              <input
                                onChange={(e) =>
                                  setloginPassword(e.target.value)
                                }
                                type="password"
                                name="logpass"
                                className="form-style"
                                placeholder="Your Otp"
                                id="logpass"
                                autocomplete="off"
                              />
                              <i className="input-icon uil uil-lock-alt"></i>
                            </div>
                            <button onClick={handleLogin} className="btn mt-4">
                              submit
                            </button>
                          </div>
                        </div>
                        <div className="text-right mr-4">
                          <button
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      {/* <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      >
                        Deactivate
                      </button> */}
                      {/* <button
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      >
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {otpin && (
            <div
              className="relative z-1000"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              <div className="fixed inset-0 z-1000 w-screen overflow-y-auto">
                <div className="min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 sm:flex">
                  <div
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                    style={{ height: "400px" }}
                  >
                    <div className="card-3d-wrapper">
                      <div className="card-front">
                        <div className="center-wrap">
                          <div className="section text-center">
                            <h4 className="mb-4 pb-3">OTP Verification</h4>
                            <OtpInput
                              containerStyle=" h-20"
                              // inputStyle={
                              //   "text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                              // }
                              inputStyle="inputStyle"
                              value={otp}
                              onChange={setOtp}
                              numInputs={4}
                              renderSeparator={<span>-</span>}
                              renderInput={(props) => <input {...props} />}
                            />
                            <button onClick={handleOtp} className="btn mt-4">
                              submit
                            </button>
                          </div>
                        </div>
                        <div className="text-right mr-4">
                          <button
                            type="button"
                            onClick={() => setotpin(!otpin)}
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      {/* <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            >
                              Deactivate
                            </button> */}
                      {/* <button
                              type="button"
                              onClick={() => setIsOpen(!isOpen)}
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                              Cancel
                            </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            onClick={() => setnav(!nav)}
            className="lg:hidden cursor-pointer pr-4  md:hidden"
            style={{ zIndex: "201" }}
          >
            {nav ? (
              <FaTimes size={22} className="text-white" />
            ) : (
              <FaBars size={22} className="text-black" />
            )}
          </div>
        </div>
        {nav && (
          <ul
            className="flex flex-col justify-center
        items-center fixed top-0 left-0 w-full
        h-screen bg-gradient-to-r from-[#fa8128] to-[#c55302] text-white "
            style={{ zIndex: "200" }}
          >
            <ul className="ml-2 xl:w-48 flex items-center justify-end">
              <nav className="contents"></nav>
            </ul>
            <li className="top-bar-menu-sec1 ">
              <div className="wrapper1">
                <div className="search_box">
                  <div className="search_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Search"
                      onChange={(e) => handleSearch(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          setnav(!nav); // Pass e.target.value instead of event
                        }
                      }}
                    />
                    <i className="fas fa-search " />
                  </div>
                  <div
                    className="dropdown"
                    onClick={() => setShowlist(!showlist)}
                  >
                    <div className="default_option">
                      {conditionsGet !== null ? conditionsGet : "All"}
                    </div>
                  </div>
                  {showlist && (
                    <div
                      className="absolute right-0 z-10 mt-5 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex={-1}
                    >
                      <div className="py-1" role="none">
                        <a
                          className="font-medium text-gray-900 block px-4 py-2 text-sm"
                          role="menuitem"
                          tabIndex={-1}
                          id="menu-item-0"
                          data-custom-value="Used"
                          onClick={(e) => hendleGetProductm(e)}
                        >
                          Used
                        </a>
                        <a
                          className="text-gray-900 block px-4 py-2 text-sm"
                          role="menuitem"
                          tabIndex={-1}
                          id="menu-item-1"
                          data-custom-value="New"
                          onClick={(e) => hendleGetProductm(e)}
                        >
                          New
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>

            <nav className="contents"></nav>
            {localStorage.getItem("just_free_token") ? (
              <li className="top-bar-menu-sec1 " onClick={() => setnav(!nav)}>
                {data ? (
                  <Link
                    to="/userprofile"
                    className="inline-flex items-center text-sm  font-semibold text-[#Fa8128] hover:text-[#c55302]"
                  >
                    <img
                      src={
                        data.image
                          ? `https://justfree.webinatech.com/admin/public/profile_image/${data.image}`
                          : "https://cdn-icons-png.flaticon.com/512/666/666201.png"
                      }
                      className="w-8 h-8 bg-gray-300 rounded-full mb-1"
                      alt="User Avatar"
                    />
                    <h6 className="text-white  pl-2  text-xl">Your profile</h6>
                  </Link>
                ) : (
                  <div>Loading...</div>
                )}
              </li>
            ) : (
              <li className="top-bar-menu-sec1" onClick={() => setnav(!nav)}>
                <button
                  onClick={() => setshowLogin(!showLogin)}
                  className="inline-flex items-center text-xl font-semibold text-white"
                >
                  Login
                </button>
              </li>
            )}
            <nav className="contents"></nav>
            {localStorage.getItem("just_free_token") ? (
              <li className="">
                <Link
                  to="/myproduct"
                  className=" text-white"
                  onClick={() => setnav(!nav)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className=" pt-2"
                  >
                    <span style={{ fontSize: "24px" }}>
                      <i className="fa-solid fa-tag"></i>
                    </span>

                    <h6 className="text-white pl-2 text-xl">My items</h6>
                  </div>
                </Link>{" "}
              </li>
            ) : (
              ""
            )}
            {localStorage.getItem("just_free_token") ? (
              <li>
                {" "}
                <Link
                  to="/Wishlist"
                  className=" text-white"
                  onClick={() => setnav(!nav)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className=" pt-2"
                  >
                    <span style={{ fontSize: "24px" }}>
                      <i className="fa-regular fa-heart"></i>
                    </span>
                    <h6 className="text-white  pl-2  text-xl "> Saved</h6>
                  </div>
                </Link>
              </li>
            ) : (
              ""
            )}
            <nav className="contents"></nav>
            {localStorage.getItem("just_free_token") ? (
              <li className="">
                <Link
                  to="/chat"
                  className=" text-white"
                  onClick={() => setnav(!nav)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className=" pt-2"
                  >
                    <span style={{ fontSize: "24px" }}>
                      <i className="fa-regular fa-message"></i>{" "}
                    </span>
                    <h6 className="text-white  pl-2  text-xl">Inbox</h6>
                  </div>
                </Link>
              </li>
            ) : (
              ""
            )}

            <nav className="contents"></nav>
            {localStorage.getItem("just_free_token") ? (
              ""
            ) : (
              <li className="top-bar-menu-sec1" onClick={() => setnav(!nav)}>
                <Link
                  to="/about"
                  className="inline-flex items-center text-xl font-semibold text-white"
                >
                  About
                </Link>
              </li>
            )}
            <nav className="contents"></nav>
            {localStorage.getItem("just_free_token") ? (
              ""
            ) : (
              <li className="top-bar-menu-sec1" onClick={() => setnav(!nav)}>
                <Link to="/help">
                  <button className="inline-flex items-center text-xl font-semibold text-white">
                    Help
                  </button>
                </Link>
              </li>
            )}
          </ul>
        )}
        <div className="navbar-sec mx-auto max-w-7xl items-center justify-between">
          <div className="row1">
            <div className="col-lg-2 col-12">
              <div className="p-2">
                <ul className="navbar-left">
                  <li>
                    <button
                      // to="/addproduct"
                      className="d-flex justify-content-start align-items-center text-lg cursor-pointer alegreya-unique-class500"
                      onClick={hendlAddProduct}
                    >
                      Sell Here <MdAdd />
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-10 col-12">
              <div className="navbar-top-right ">
                {/* <ul className="navbar-right-sec">
                  {categoryList.map((item) => (
                    <li key={item.id}>
                      <a
                        className="hover:text-orange-600"
                        onClick={() => handleFilterCategories(item.id)}
                      >
                        {item.category_name}
                      </a>
                    </li>
                  ))}
                </ul> */}
                <div className="menu-item menu-dropdown navbar-right-sec">
                  {categoryList.map((item) => (
                    <li key={item.id}>
                      <span
                        className="menu-link flex cursor-pointer"
                        onMouseEnter={(e) => hendleTopHeader(e, item.id)}
                      >
                        {item.category_name}
                        {/* <IoMdArrowDropdown size={25} /> */}
                      </span>
                    </li>
                  ))}

                  <div className="submenu megamenu megamenu-column-4">
                    {categorybysub.map((category, i) => (
                      <div className="submenu-inner" key={i}>
                        <h4
                          className="submenu-title cursor-pointer"
                          onClick={() => handleFilterCategories(category.id)}
                        >
                          {category.category_name}
                        </h4>
                        {/* <ul className="submenu-list">
                          {category.chield.map((subCategory, j) => (
                            <li
                              className="submenu-item hover:text-orange-600"
                              key={j}
                            >
                              <a
                                onClick={() =>
                                  handleFilterCategories(subCategory.id)
                                }
                                className="submenu-link"
                              >
                                {subCategory.category_name}
                              </a>
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="menu-item menu-dropdown">
                  <span className="menu-link flex">
                    More <IoMdArrowDropdown size={25} />
                  </span>
                  <div className="submenu megamenu megamenu-column-4">
                    {subCategory.map((category, i) => (
                      <div className="submenu-inner" key={i}>
                        <h4 className="submenu-title">
                          {category.category_name}
                        </h4>
                        <ul className="submenu-list">
                          {category.chield.map((subCategory, j) => (
                            <li
                              className="submenu-item hover:text-orange-600"
                              key={j}
                            >
                              <a
                                onClick={() =>
                                  handleFilterCategories(subCategory.id)
                                }
                                className="submenu-link"
                              >
                                {subCategory.category_name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>

                {/* location-model-start        */}

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  className="locationmodel_1"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {" "}
                      <span> Location </span>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* <img src={locationimg} alt="logo" className="locationimg-1"/>
                  Please provide your delivery location to see products at nearby store */}
                    <div className="locaton_div_1">
                      {/* <Button className="use_locaton"> Type manually </Button> */}
                      <form>
                        <lavel for=""> ZIP Code </lavel> <br />
                        <input
                          type="text"
                          className="search_locaton"
                          placeholder={pin}
                        />
                      </form>
                      <div className="slider_sec">
                        <p className="m-0"> Distance </p>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={4}
                          getAriaValueText={valuetext}
                          valueLabelDisplay="auto"
                          step={3}
                          marks
                          min={1}
                          max={10}
                        />
                        <div onClick={hendleZipProduct} className="text-center">
                          <Button className="use_locaton"> Submit </Button>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                {/* location-model-end        */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navebar;
