import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="relative overflow-hidden py-10 bg-[#Fa8128]">
      <div className="relative z-10 mx-auto max-w-7xl px-4">
        <div className="-m-6 flex flex-wrap">
          <div className="w-full p-6 md:w-1/2 lg:w-5/12">
            <div className="flex h-full flex-col justify-between">
              <div className="mb-4 inline-flex items-center">
                <span className="ml-4 font-bold text-2xl text-white">
                  JustFree
                </span>
              </div>
              <div>
                {/* <p className="mb-4  text-base font-medium text-white">
                  The Tailwind CSS Component library
                </p> */}
                <p className="text-sm text-white">
                  &copy; Copyright 2024. All Rights Reserved by JustFree.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9 text-base font-semibold uppercase text-white">
                Shop
              </h3>
              <ul>
                <li className="mb-4">
                  <a
                    className=" text-base font-medium text-white hover:text-gray-700"
                    href="#"
                  >
                    New
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    className=" text-base font-medium text-white hover:text-gray-700"
                    href="#"
                  >
                    Used
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9  text-base font-semibold uppercase text-white">
                About
              </h3>
              <ul>
                <li className="mb-4">
                  <Link
                    className=" text-base font-medium text-white hover:text-gray-700"
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    className=" text-base font-medium text-white hover:text-gray-700"
                    to="/help"
                  >
                    Help
                  </Link>
                </li>
                {/* <li className="mb-4">
                  <a
                    className=" text-base font-medium text-white hover:text-gray-700"
                    href="#"
                  >
                    Contact Us
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className=" text-base font-medium text-white hover:text-gray-700"
                    href="#"
                  >
                    Customer Support
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-3/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9  text-base font-semibold uppercase text-white">
                Policy
              </h3>
              <ul>
                <li className="mb-4">
                  <Link
                    className=" text-base font-medium text-white hover:text-gray-700"
                    to="/termsConditions"
                  >
                    Terms &amp; Conditions
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    className=" text-base font-medium text-white hover:text-gray-700"
                    to="/privacypolicy"
                  >
                    Privacy Policy
                  </Link>
                </li>
                {/* <li>
                  <a
                    className=" text-base font-medium text-white hover:text-gray-700"
                    href="#"
                  >
                    Licensing
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
