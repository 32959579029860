import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navebar from "./components/nav/Navebar";
import Footer from "./components/footer/Footer";
import Products from "./pages/Products";
import Product from "./pages/Product";
import { ToastContainer } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import Userprofile from "./pages/Userprofile";
import About from "./pages/About";
import Help from "./pages/help";
import Tandc from "./components/footer/Tandc";
import Privacy from "./components/footer/Privacy";
import Chat from "./pages/Chat";
import Addproduct from "./pages/Addproduct";
import Wishlist2 from "./pages/Wishlist2";
import Myproduct2 from "./pages/Myproduct2";
import EditProduct from "./pages/EditProduct";

function App() {
  return (
    <>
      <Navebar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:slug" element={<Product />} />
        <Route path="/userprofile" element={<Userprofile />} />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<Help />} />
        <Route path="/termsConditions" element={<Tandc />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/addproduct" element={<Addproduct />} />
        <Route path="/myproduct" element={<Myproduct2 />} />
        <Route path="/wishlist" element={<Wishlist2 />} />
        <Route path="/editProduct" element={<EditProduct />} />
      </Routes>
      <ToastContainer floatingTime={3000} />
      <Footer/>
    </>
  );
}

export default App;
