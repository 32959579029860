import { Edit_Product,Empty_Edit_Product } from "./constant";
export const EditData = (data = [], action) => {
    switch (action.type) {
        case Edit_Product:
            console.warn("Edit_Product ", action)
            return [action.data, ...data]
            case Empty_Edit_Product:
                console.log("Empty_Cart called", action);
                data = [];
                return [...data];
        default:
            return []
    }
}