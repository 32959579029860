import React, { useState, useEffect } from "react";
import Categoryproduct from "../components/categoryproduct/Categoryproduct";
import "../components/component.css";
// import Homeproducts from "../components/homeproducts/Homeproducts";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";

import { useDispatch } from "react-redux";
import { productByCategory, productByCondition } from "../redux/productAction";
// import { useSelector } from "react-redux";

const Products = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showlist, setShowlist] = useState(false);
  const [progress, setProgress] = useState(0);

  //categories
  const [categoryList, setCategorylist] = useState([]);

  //conditions
  const [conditions, setconditions] = useState([]);

  useEffect(() => {
    setProgress(50);
    const handleCategories = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_API_CATEGORIES
          )
          .then((res) => {
            setCategorylist(res.data.data);
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    const handleConditions = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_API_CONDITIONS
          )
          .then((res) => {
            setconditions(res.data.data);
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    handleCategories();
    handleConditions();
  }, []);

  const handleFilterCategories = (id) => {
    setProgress(50);

    dispatch(productByCategory(id));
    setProgress(100);

    console.log("usuusususus", id);
  };

  const handleFilterConditions = (query) => {
    setProgress(50);

    dispatch(productByCondition(query));
    setProgress(100);

    console.log("usuusususus", query);
  };

  return (
    <>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="bg-white product-sec">
        <div>
          {show && (
            <div
              className="relative z-40 lg:hidden"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
              <div className="fixed inset-0 z-40 flex">
                <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      onClick={() => setShow(!show)}
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <form className="mt-4 border-t border-gray-200">
                    <h3 className=" ml-5">Categories</h3>
                    <ul
                      role="list"
                      className="px-2 py-3 font-medium text-gray-900"
                    >
                      {categoryList.map((item) => (
                        <li key={item.id}>
                          <a
                            onClick={() => handleFilterCategories(item.id)}
                            className="block px-2 py-3 text-gray-900"
                          >
                            {item.category_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                    {/* <div className="border-t border-gray-200 px-4 py-6">
                      <h3 className="-mx-2 -my-3 flow-root">
                        <button
                          type="button"
                          className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-mobile-0"
                          aria-expanded="false"
                        >
                          <span className="font-medium text-gray-900">
                            Color
                          </span>
                        </button>
                      </h3>

                      <div className="pt-6" id="filter-section-mobile-0">
                        <div className="space-y-6"></div>
                      </div>
                    </div> */}
                    <div className="border-t border-gray-200 px-4 py-6">
                      <h3 className="-mx-2 -my-3 flow-root">
                        <button
                          type="button"
                          className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-mobile-1"
                          aria-expanded="false"
                        >
                          <span className="font-medium text-gray-900">
                            Conditions
                          </span>
                        </button>
                      </h3>

                      <div className="pt-6" id="filter-section-mobile-1">
                        <div className="space-y-6">
                          {conditions.map((item) => (
                            <div
                              key={item.id}
                              onClick={() =>
                                handleFilterConditions(item.condition_name)
                              }
                              className="flex items-center"
                            >
                              <input
                                id="filter-mobile-category-0"
                                name="category[]"
                                defaultValue="new-arrivals"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor="filter-mobile-category-0"
                                className="ml-3 min-w-0 flex-1 text-gray-500"
                              >
                                {item.condition_name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
            
                  </form>
                </div>
              </div>
            </div>
          )}
          <main className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-lg items-baseline justify-between  border-gray-200 pt-6">
              <h1 className="text-3xl font-bold ml-[339px] tracking-tight text-gray-900">

              </h1>
              <div className="flex items-center">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      onClick={() => setShowlist(!showlist)}
                      className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                      id="menu-button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      Sort
                      <svg
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  {showlist && (
                    <div
                      className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex={-1}
                    >
                      <div className="py-1" role="none">
                        <a
                          href="#"
                          className="text-gray-500 block px-4 py-2 text-sm"
                          role="menuitem"
                          tabIndex={-1}
                          id="menu-item-2"
                        >
                          Newest
                        </a>
                        <a
                          href="#"
                          className="text-gray-500 block px-4 py-2 text-sm"
                          role="menuitem"
                          tabIndex={-1}
                          id="menu-item-3"
                        >
                          Price: Low to High
                        </a>
                        <a
                          href="#"
                          className="text-gray-500 block px-4 py-2 text-sm"
                          role="menuitem"
                          tabIndex={-1}
                          id="menu-item-4"
                        >
                          Price: High to Low
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                >
                  <span className="sr-only">Filters</span>
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 01.628.74v2.288a2.25 2.25 0 01-.659 1.59l-4.682 4.683a2.25 2.25 0 00-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 018 18.25v-5.757a2.25 2.25 0 00-.659-1.591L2.659 6.22A2.25 2.25 0 012 4.629V2.34a.75.75 0 01.628-.74z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <section aria-labelledby="products-heading" className="pb-24 pt-6 products-secc">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="d-flex">
                <form className="hidden lg:block">
                  <div className="categories-product-title">
                    <h3 className="sr-only">Categories</h3>
                    <span className=" font-semibold text-gray-900">
                      All Categories
                    </span>
                    <ul
                      role="list"
                      className="space-y-4 border-b mt-7 border-gray-900 pb-6 text-sm font-medium"
                    >
                      {categoryList.map((item) => (
                        <li key={item.id}>
                          <a
                            onClick={() => handleFilterCategories(item.id)}
                            className="text-[#757575] text-block font-semibold"
                          >
                            {item.category_name}
                          </a>
                        </li>
                      ))}

                      {/* <li>
                      <a className="text-[#757575]" href="#">
                        Jewellery & Accessaries
                      </a>
                    </li> */}
                    </ul>
                    <div className="border-b border-gray-900 py-6">
                      <h3 className="-my-3 flow-root">
                        <button
                          type="button"
                          className="flex w-full items-center justify-center bg-white py-1 text-sm text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-0"
                          aria-expanded="false"
                        >
                          <span className=" text-block font-semibold">
                            Filters
                          </span>
                        </button>
                        <span className="text-block font-semibold text-sm">
                          Percentage
                        </span>
                        <div className="mt-3">
                          <form action="" method="get">
                            <div className="row">
                              <div className="col-lg-4 col-12 p-0">
                                <div className="form-group">
                                  <input type="number" className="form-control" placeholder="Min" required />
                                </div>
                              </div>
                              <div className="col-lg-2 col-12 p-0">
                                <p className="m-0 p-2"> to </p>
                              </div>
                              <div className="col-lg-4 col-12 p-0">
                                <div className="form-group">
                                  <input type="number" className="form-control" placeholder="Max" required />
                                </div>
                              </div>
                              <div className="col-lg-2 col-12 p-0">
                                <button type="submit" className="btn-primary seacher-icon"> <i className="fa-solid fa-magnifying-glass"></i> </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </h3>

                      <div className="pt-6" id="filter-section-0">
                        <div className="space-y-4"></div>
                      </div>
                    </div>
                    <div className="border-b border-gray-900 py-6">
                      <h3 className="-my-3 flow-root">
                        <button
                          type="button"
                          className="flex w-full items-center  justify-center bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-1"
                          aria-expanded="false"
                        >
                          <span className=" font-bold text-gray-900">
                            Condition
                          </span>
                        </button>
                      </h3>

                      <div className="pt-6" id="filter-section-1">
                        <div className="space-y-4">
                          {conditions.map((item) => (
                            <div
                              key={item.id}
                              onClick={() =>
                                handleFilterConditions(item.condition_name)
                              }
                              className="flex items-center"
                            >
                              <input
                                id="filter-category-0"
                                name="category[]"
                                defaultValue="new-arrivals"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor="filter-category-0"
                                className="ml-3 text-sm text-block font-semibold ">
                                {item.condition_name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
     
                  </div>
                </form>
                <Categoryproduct />
                {/* <Homeproducts /> */}
                <div className="lg:col-span-3">{/* Your content */}</div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default Products;
