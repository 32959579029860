import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { useSelector } from "react-redux";
import { TbMapPinFilled } from "react-icons/tb";
const Categoryproduct = () => {
  const data = useSelector((state) => state.productData);

  console.log("redux-data", data);
  const [show, setShow] = useState(false);
  const [showlist, setShowlist] = useState(false);

  return (
    <div>
      {data.length !== 0 ? (
        <div className="categories-product-show">
          <div className="flex items-baseline justify-between border-gray-200 pb-6 pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              {data[0].product_category_name}
            </h1>
            <div className="flex items-center">
              {/* <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    onClick={() => setShowlist(!showlist)}
                    className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                    id="menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    Sort
                    <svg
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {showlist && (
                  <div
                    className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex={-1}
                  >
                    <div className="py-1" role="none">
                      <a
                        href="#"
                        className="text-gray-500 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabIndex={-1}
                        id="menu-item-2"
                      >
                        Newest
                      </a>
                      <a
                        href="#"
                        className="text-gray-500 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabIndex={-1}
                        id="menu-item-3"
                      >
                        Price: Low to High
                      </a>
                      <a
                        href="#"
                        className="text-gray-500 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabIndex={-1}
                        id="menu-item-4"
                      >
                        Price: High to Low
                      </a>
                    </div>
                  </div>
                )}
              </div> */}

              {/* <button
                type="button"
                onClick={() => setShow(!show)}
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              >
                <span className="sr-only">Filters</span>
                <svg
                  className="h-5 w-5"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 01.628.74v2.288a2.25 2.25 0 01-.659 1.59l-4.682 4.683a2.25 2.25 0 00-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 018 18.25v-5.757a2.25 2.25 0 00-.659-1.591L2.659 6.22A2.25 2.25 0 012 4.629V2.34a.75.75 0 01.628-.74z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}
            </div>
          </div>
          <div className="mx-auto grid w-full max-w-7xl items-center space-y-4 md:grid-cols-2 md:gap-6 md:space-y-0 lg:grid-cols-4">
            {data.map((item) => (
              <div key={item.product_id} className="rounded-md border">
                <Link to={`/product/${item.product_uniqueid}`}>
                  {item.product_image[0]?.image ? (
                    <img style={{objectFit: 'contain'}}
                      src={item.product_image[0].image}
                      alt="Laptop"
                      className="aspect-[16/9] w-full rounded-md md:aspect-auto md:h-[300px] lg:h-[200px]"
                    />
                  ) : (
                    ""
                  )}
                </Link>

                <div className="p-4">
                  {/* <div className="offce-and-product-status">
                        
                        <div className="item">{item.product_condition}</div> 

                      </div> */}
                  <Link to={`/product/${item.product_uniqueid}`}>
                    <h1 className="inline-flex items-center text-lg font-semibold text-black">
                      {item.product_name}
                    </h1>
                    <div className="mt-2 flex items-center space-x-2 text-black">
                      <span className="block text-sm font-semibold text-black">₹ {item.product_price} </span>
                    </div>
                    <div className="mt-1 flex items-center space-x-2 text-black">
                      <i className="fa-regular fa-clock"></i>
                      <h6 className=" mt-2 text-sm">{item.time}</h6>
                    </div>
                    <div className="mt-2 flex items-center space-x-2">
                  <TbMapPinFilled className="flex text-orange-500" />
                  <span className="block text-xs font-medium text-black">
                    {item.city}, {item.country}{" "}
                  </span>
                </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h1 className=" text-end text-2xl"> data not available</h1>
      )}
    </div>
  );
};

export default Categoryproduct;
