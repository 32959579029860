import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../component.css";
import { toast } from "react-custom-alert";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

const Slider = (props) => {
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(50);
    const handleUserData = async () => {
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_PRODUCT}`,
            {
              product_id: props.id,
            }
          )
          .then((res) => {
            console.log("llll", res.data.products);
            setData(res.data.products);
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };
    handleUserData();
  }, [props]);

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <h1 className="text-title">User items</h1>
      <OwlCarousel
        className="owl-theme mx-auto grid w-full max-w-7xl items-center space-y-4 px-2 py-10 md:grid-cols-2 md:gap-6 md:space-y-0 lg:grid-cols-4"
        loop={true}
        margin={20}
        autoplay={false}
        autoplayTimeout={2500}
        autoplaySpeed={2000}
        dots={false}
        nav={true}
        navText={[
          '<i class="fa-solid fa-chevron-left"></i>',
          '<i class="fa-solid fa-chevron-right"></i>',
        ]}
        responsive={{
          0: {
            items: 1,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 4,
          },
        }}
      >
        {data.map((item) => (
          <div key={item.product_id} className="rounded-md border">
            <Link to={`/product/${item.product_uniqueid}`}>
              {item.product_image &&
              item.product_image[0] &&
              item.product_image[0].image ? (
                <img
                  src={item.product_image[0].image}
                  alt="Laptop"
                  className="aspect-[16/9] w-full rounded-md md:aspect-auto md:h-[300px] lg:h-[200px]"
                />
              ) : (
                ""
              )}
            </Link>

            <div className="p-4">
            <Link to={`/product/${item.product_uniqueid}`}>
              <h1 className="inline-flex items-center text-lg font-semibold text-black">
                {item.product_name}
              </h1>
              <div className="mt-2 flex items-center space-x-2">
                <span className="block text-sm font-semibold text-black">
                  ₹ {item.product_price}{" "}
                </span>
              </div>
              <div className="mt-2 flex items-center space-x-2">
                <span className="block text-sm font-semibold text-black">
                  {item.city},{item.country}{" "}
                </span>
              </div>
              </Link>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Slider;
