import {Set_Distance_Product } from "./constant";

export const productDistanceData = (data = [], action) => {
  switch (action.type) {
      case Set_Distance_Product:
        console.log("Product_List called", action);
        return [...action.data];

    default:
      return data;
  }
};