import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import "../App.css";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";

const Help = () => {
  const [progress, setProgress] = useState(0);
  const [getimg, setgetimg] = useState(null);

  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [mobile, setmobile] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    const handleFaq = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_FAQ
          )
          .then((res) => {
            setgetimg(res.data.faqs);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };
    handleFaq();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_API_CONTACT_US,
          {
            name: name,
            email: email,
            mobile: mobile,
            message: message,
          }
        )
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          if (error.response) {
            // Handle errors with a response (e.g., server errors)
            console.log("Data :", error.response.data);
            toast.warning(error.response.data.message);
            console.log("Status :" + error.response.status);
            setProgress(100);
          } else if (error.request) {
            // Handle errors without a response (e.g., network errors)
            console.log("No response received.");
            toast.error("No response received.");
            setProgress(100);
          } else {
            // Handle other errors (e.g., setup errors)
            console.log("Error", error.message);
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <section className="pb-20 pt-20 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h1 className=" text-3xl font-bold">Contact us</h1>
              <form className="help-left-sec">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group mb-2">
                      <label for="">Name</label>
                      <input
                        onChange={(e) => setname(e.target.value)}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder=" Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group mb-2">
                      <label for="exampleInputEmail1">Email</label>
                      <input
                        onChange={(e) => setemail(e.target.value)}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder=" Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-12 col-12 mb-2">
                  <label for="exampleInputEmail1">Mobile Number</label>
                  <input
                    onChange={(e) => setmobile(e.target.value)}
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder=" Mobile Number"
                  />
                </div>
                <div className="form-group col-lg-12 col-12 mb-2">
                  <label for="">Massage</label>
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control"
                    id=""
                    rows="6"
                    placeholder="Massage"
                  ></textarea>
                </div>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </form>
            </div>
            {getimg && Array.isArray(getimg) ? (
              getimg.map((item) => (
                <div key={item.id} className="col-lg-6 col-12">
                  <h1 className=" text-3xl font-bold">FAQ</h1>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{item.question}</Accordion.Header>
                      <Accordion.Body>{item.answer}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))
            ) : (
              // Render an alternative content or message when getimg is null or not an array
              <div>No data available</div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Help;
