import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { useDispatch } from "react-redux";
import {editProduct,emtiEditProduct} from "../redux/productAction";
import { FaEdit } from "react-icons/fa";
import { TbMapPinFilled } from "react-icons/tb";
import "../Font.css";
const Myproduct = () => {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //my-product
  const [myProduct, setmyProduct] = useState([]);

  useEffect(() => {
    setProgress(50);
    const handleMyProduct = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_API_MY_PRODUCT,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(
                  localStorage.getItem("just_free_token")
                )}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setmyProduct(res.data.products);
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };
    handleMyProduct();
  }, []);

  const btnSold = async (id) => {
    try {
      const formData = new FormData();
      formData.append("product_id", id);

      const response = await axios.post(
        "https://justfree.webinatech.com/admin/public/api/product-sold",
        formData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("just_free_token")
            )}`,
            "content-type": "multipart/form-data",
          },
        }
      );

      toast.success(response.data.message);
      window.location.reload(false);
      // console.log(response)
      // Alternatively, use history.push('/userprofile') for navigation
    } catch (error) { }
  }

  console.log(myProduct)

  const handleEditProduct = (data) => {
    dispatch(emtiEditProduct())
    dispatch(editProduct(data))
    navigate('/editProduct');
  }

  return (
    <div className="bg-white min-h-screen">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="mx-auto grid w-full max-w-7xl items-center space-y-4 px-2 py-10 md:grid-cols-2 md:gap-6 md:space-y-0 lg:grid-cols-4">
        {myProduct.map((item) => (
          <div key={item.product_id} className="rounded-md border">
           <div className="relative">

<Link to={`/product/${item.product_uniqueid}`}>
  <img
    src={item.product_image[0].image}
    alt="Laptop"
    className="aspect-[16/9] w-full rounded-md md:aspect-auto md:h-[300px] lg:h-[200px]"
  />
</Link>
<div className="absolute top-3 right-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
    <button id={item.product_id} onClick={()=>handleEditProduct(item)}>
      <FaEdit
        className="text-orange-400 hover:text-orange-600"
        size={20}
      />
    </button>
  </div>
</div>
            <div className="p-4">
              <h1 className="inline-flex items-center text-lg font-semibold alegreya-unique-class">
                {item.product_name}
              </h1>
              <div className="mt-2 flex items-center space-x-2">
                <span className="block text-sm font-semibold">
                  ₹ {item.product_price}{" "}
                </span>
              </div>
              <div className="mt-1 flex items-center space-x-2">
                <i className="fa-regular fa-clock"></i>
                <h6 className=" mt-2 text-sm">{item.time}</h6>
              </div>
              <div className="mt-2 flex items-center space-x-2">
                  <TbMapPinFilled className="flex text-orange-500" />
                  <span className="block text-xs font-medium text-black">
                    {item.city}, {item.country}{" "}
                  </span>
                </div>
              <button disabled={item.product_status == "Sold"} className={` ${item.product_status == "Sold" ? "sold-button1" : "sold-button"}`}
                onClick={() => btnSold(item.product_id)}>Sold</button>
           
                <button  className="sold-button ml-3" 
               >Undo</button>


            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default Myproduct;
