import React, { useState, useEffect, useRef } from "react";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { RiChatHistoryFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { CiLogout } from "react-icons/ci";
import Myproduct from "./Myproduct";
import Wishlist from "./Wishlist";
import { RiProductHuntLine } from "react-icons/ri";
const Userprofile = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const [data, setData] = useState([]);

  const [show, setshow] = useState(false);

  useEffect(() => {
    setProgress(50);

    // Check if the token is present in localStorage
    const userToken = localStorage.getItem("just_free_token");

    if (userToken) {
      const handleUserData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_DETAILS}`,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(userToken)}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log(response.data.data);
          setData(response.data.data);
          setProgress(100);
        } catch (error) {
          if (error.response) {
            // Handle errors with a response (e.g., server errors)
            console.log("Data :", error.response.data);
            toast.warning(error.response.data.message);
            console.log("Status :" + error.response.status);
            setProgress(100);
          } else if (error.request) {
            // Handle errors without a response (e.g., network errors)
            console.log("No response received.");
            toast.error("No response received.");
            setProgress(100);
          } else {
            // Handle other errors (e.g., setup errors)
            console.log("Error", error.message);
            toast.error(error.message);
            setProgress(100);
          }
        }
      };

      handleUserData();
    } else {
      // Token doesn't exist in localStorage
      // Handle this condition, e.g., show a message to the user
      toast.warning("Please log in to access this feature.");
      setProgress(100);
    }
  }, []);

  const handleLogout = () => {
    console.log("logout");
    localStorage.removeItem("just_free_token");
    localStorage.removeItem("LoginUser");
    localStorage.removeItem("userId");
    localStorage.removeItem("longitude");
    localStorage.removeItem("latitude");
    navigate("/");
    window.location.reload(false);
  };

  const handleWishlist = () => {
    navigate("/Wishlist");
  };

  const [name, setName] = useState();
  const [phone, setphone] = useState();
  const [address, setaddress] = useState();
  useEffect(() => {
    setName(data.name);
    setphone(data.phone);
    setaddress(data.address);
  }, [data.name || data.phone || data.address]);

  const handleEdit = (e) => {
    e.preventDefault();
    try {
      console.log(name, phone, address);
      axios(
        "https://justfree.webinatech.com/admin/public/api/user-details-edit",
        {
          method: "POST",
          data: {
            name: name,
            phone: phone,
            address: address,
          },
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("just_free_token")
            )}`,

            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          toast.success(res.data.message);
          setshow(!show);
          console.log(res.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  const [img, setImg] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // You can use the reader.result to display the selected image if needed
        // For example: setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
    setImg(file);
    handleImageUpload(file);
  };

  const handleUploadClick = () => {
    // Trigger file input click
    fileInputRef.current.click();
    handleImageUpload();
  };

  const handleImageUpload = async (file) => {
    // Call the userUploadImgApi function with the selected image
    console.log("uuu");
    try {
      const formData = new FormData();
      formData.append("profile_image", file);

      const response = await axios.post(
        "https://justfree.webinatech.com/admin/public/api/user-profile-image",
        formData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("just_free_token")
            )}`,
            "content-type": "multipart/form-data",
          },
        }
      );

      setProgress(100);
      toast.success(response.data.message);
      // Alternatively, use history.push('/userprofile') for navigation
    } catch (error) {}
  };
  console.log("Updated name:", name, data.name);

  const [showin, setshowin] = useState("My-Profile");

  console.log(showin);

  return (
    <>
      <div className="bg-gray-100">
        <LoadingBar
          color="#FF8000"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="container mx-auto py-8">
          <div className="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
            <div className="col-span-4 sm:col-span-3">
              <div className="bg-white shadow rounded-lg p-6">
                {/* <div className=" flex flex-col items-end">
                  <FaEdit />
                </div> */}
                {data ? (
                  <div className="flex flex-col items-center relative">
                    <img
                      src={
                        data.image
                          ? img
                            ? URL.createObjectURL(img)
                            : `https://justfree.webinatech.com/admin/public/profile_image/${data.image}`
                          : "https://cdn-icons-png.flaticon.com/512/666/666201.png"
                      }
                      className="w-32 h-32 bg-gray-300 rounded-full mb-4"
                      alt="User Avatar"
                    />

                    <div
                      className="w-32 h-32 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500"
                      onClick={handleUploadClick}
                    >
                      <img
                        className="hidden group-hover:block w-12"
                        src="https://www.svgrepo.com/show/33565/upload.svg"
                        alt="Upload Icon"
                      />
                      <input
                        id={`dropzone-file`}
                        type="file"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </div>
                    <h1 className="text-xl font-bold">{data.name}</h1>
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
                <hr className="my-6 border-t border-gray-300" />
                <div className="flex flex-col">
                  <span className="text-gray-600 uppercase font-bold tracking-wider mb-2">
                    Account info
                  </span>
                  <ul className="flex flex-col">
                    {" "}
                    {/* Apply flex-col class here */}
                    {/* <li class="my-px">
                      <a
                        href="#"
                        class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 hover:text-orange-600"
                      >
                        <span class="flex items-center justify-center text-lg text-gray-900">
                          <RiChatHistoryFill />
                        </span>
                        <span class="ml-3">Chat history</span>
                      </a>
                    </li> */}
                    <li class="my-px">
                      <a
                        onClick={() => setshowin("My-Product")}
                        class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 hover:text-orange-600"
                      >
                        <span class="flex items-center justify-center text-lg text-gray-900">
                        <RiProductHuntLine />
                        </span>
                        <span class="ml-3">My Product</span>
                      </a>
                    </li>
                    <li class="my-px">
                      <a
                        onClick={() => setshowin("My-Profile")}
                        class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 hover:text-orange-600"
                      >
                        <span class="flex items-center justify-center text-lg text-gray-900">
                          <CgProfile />
                        </span>
                        <span class="ml-3">My Profile</span>
                      </a>
                    </li>
                    <li class="my-px">
                      <a
                         onClick={() => setshowin("Wishlist")}
                        class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 hover:text-orange-600"
                      >
                        <span class="flex items-center justify-center text-lg text-gray-900">
                          <svg
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                          </svg>
                        </span>
                        <span class="ml-3">Wishlist</span>
                      </a>
                    </li>
                    <li class="my-px">
                      <a
                        onClick={handleLogout}
                        class="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 hover:text-orange-600"
                      >
                        <span class="flex items-center justify-center text-lg 0 text-gray-900">
                          <CiLogout c />
                        </span>
                        <span class="ml-3">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {showin == "My-Product" ? (
              <Myproduct />
            ): showin == "My-Profile" ?(
              <div className="col-span-4 sm:col-span-9">
                <div className="bg-white shadow rounded-lg p-6">
                  <h3
                    className="text-base text-center mt-3 -mb-2"
                    style={{ color: "#F97316" }}
                  >
                    Improve your presence in JustFree by verifying.
                  </h3>
                  <div className="flex justify-center items-center gap-14 my-6">
                    <a
                      className="text-gray-700 hover:text-orange-600"
                      aria-label="Visit TrendyMinds LinkedIn"
                      href=""
                      target="_blank"
                    >
                      <MdEmail size={30} />
                    </a>
                    <a
                      className="text-gray-700 hover:text-orange-600"
                      aria-label="Visit TrendyMinds YouTube"
                      href=""
                      target="_blank"
                    >
                      <BsFillTelephoneFill size={25} />
                    </a>
                  </div>

                  <div className="mt-20 text-left bg-white text-gray-700">
                    <h2 className="text-xl font-bold mt-2 mb-6">Account</h2>

                    <div className="main-body p-15">
                      {data ? (
                        <div className="">
                          <div className="card-body ">
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Full Name</h6>
                              </div>
                              <div className="col-sm-9 text-gray-600">
                                {data.name}
                              </div>
                            </div>
                            <hr className="mb-3 my-3" />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Email</h6>
                              </div>
                              <div className="col-sm-9 text-gray-600">
                                {data.email}
                              </div>
                            </div>
                            <hr className="mb-3 my-3" />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Phone</h6>
                              </div>
                              <div className="col-sm-9 text-gray-600">
                                {data.phone}
                              </div>
                            </div>
                            <hr className="mb-3 my-3" />
                            <div className="row">
                              <div className="col-sm-3">
                                <h6 className="mb-0">Address</h6>
                              </div>
                              <div className="col-sm-9 text-gray-600">
                                {data.address}
                              </div>
                            </div>
                            <hr className="mb-3 my-3" />
                            <div className="row">
                              <div className="col-sm-12">
                                <a
                                  onClick={() => setshow(!show)}
                                  className="btn btn-info"
                                  target="__blank"
                                >
                                  Edit
                                </a>
                              </div>

                              {show && (
                                <div
                                  className="relative z-10"
                                  aria-labelledby="modal-title"
                                  role="dialog"
                                  aria-modal="true"
                                >
                                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                  <div className="fixed inset-0 z-10 w-screen overflow-y-auto mt-24">
                                    <div className="flex  items-end justify-center p-8 text-center sm:items-center sm:p-0">
                                      <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 h-2/3 sm:w-full sm:max-w-4xl">
                                      <form className=" p-12">
                                        {/* <div className="relative z-0 w-full mb-6 group">
                                          <input
                                            type="text"
                                            name="Full Name"
                                            id="Full Name"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                            required=""
                                          />
                                          <label
                                            htmlFor="floating_email"
                                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                          >
                                            Full Name
                                          </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                          <input
                                            type="password"
                                            name="floating_password"
                                            id="floating_password"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                            required=""
                                          />
                                          <label
                                            htmlFor="floating_password"
                                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                          >
                                            Password
                                          </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                          <input
                                            type="password"
                                            name="repeat_password"
                                            id="floating_repeat_password"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                            required=""
                                          />
                                          <label
                                            htmlFor="floating_repeat_password"
                                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                          >
                                            Confirm password
                                          </label>
                                        </div> */}
                                        <div className="grid md:grid-cols-2 md:gap-6">
                                          <div className="relative z-0 w-full mb-6 group">
                                            <label
                                              htmlFor="floating_first_name"
                                              className="mb-1 block text-base font-medium text-[#07074D]"
                                            >
                                              Full name
                                            </label>
                                            <input
                                              type="text"
                                              name="floating_first_name"
                                              id="floating_first_name"
                                              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#eb9e56] focus:shadow-sm"
                                              required=""
                                              value={name}
                                              onChange={(e) =>
                                                setName(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="relative z-0 w-full mb-6 group">
                                            <label
                                              htmlFor="floating_phone"
                                              className="mb-1 block text-base font-medium text-[#07074D]"
                                            >
                                              Phone number
                                            </label>
                                            <input
                                              type="number"
                                              // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                              name="floating_phone"
                                              id="floating_phone"
                                              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-sm"
                                              // placeholder={data.phone}
                                              required=""
                                              value={phone}
                                              onChange={(e) =>
                                                setphone(e.target.value)
                                              }
                                            />
                                          </div>
                                          {/* <div className="relative z-0 w-full mb-6 group">
                                            <input
                                              type="text"
                                              name="floating_last_name"
                                              id="floating_last_name"
                                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              placeholder=" "
                                              required=""
                                            />
                                            <label
                                              htmlFor="floating_last_name"
                                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                              zip code
                                            </label>
                                          </div> */}
                                        </div>
                                        <div className="grid md:grid-cols-2 md:gap-6 mt-1">
                                          <div className="relative z-0 w-full mb-6 group">
                                            <label
                                              htmlFor="floating_company"
                                              className="mb-1 block text-base font-medium text-[#07074D]"
                                            >
                                              Address
                                            </label>
                                            <input
                                              type="text"
                                              name="floating_company"
                                              id="floating_company"
                                              className="w-full rounded-md border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] border focus:shadow-sm"
                                              // placeholder= {data.address}
                                              required=""
                                              value={address}
                                              onChange={(e) =>
                                                setaddress(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <button
                                          disabled={!name || !phone || !address}
                                          onClick={handleEdit}
                                          type="submit"
                                          className={`text-white cursor-pointer focus:ring-4 ${
                                            !name || !phone || !address
                                              ? " bg-gray-500"
                                              : "bg-[#Fa8128] hover:bg-[#c55302]"
                                          } focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-4`}
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() => setshow(!show)}
                                          className="text-white ml-3 bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                                        >
                                          Cancel
                                        </button>
                                      </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ):showin == "Wishlist" ? <Wishlist/> : "" }
          </div>
        </div>
      </div>
    </>
  );
};

export default Userprofile;
