import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
const Tandc = () => {
  const [tcData, settcData] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const faq = async () => {
      try {
        await axios("https://justfree.webinatech.com/admin/public/api/trams", {
          method: "GET",
        })
          .then((res) => {
            setProgress(75);
            settcData(res.data.trams);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              toast.warning(error.response.data.message);

              setProgress(100);
            } else if (error.request) {
              toast.error(error.request);
              setProgress(100);
            } else {
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq();
  }, []);

  return (
    <div className=" bg-white">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="container mx-auto px-4">
        <div className="gradient-form bg-white p-5 ">
          <p className="text-3xl font-fontHading leading-9 text-center text-gray-800 lg:text-4xl">
            Terms And Conditions
          </p>
          <p
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: tcData.content }}
          />
        </div>
      </div>
    </div>
  );
};

export default Tandc;
