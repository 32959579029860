import { Category_Product, Condition_Product, Get_Distance_Product,Edit_Product,Empty_Edit_Product } from "./constant";

export const productByCategory = (id) => {
  return {
    type: Category_Product,
    id,
  };
};

export const productByCondition = (query) => {
  return {
    type: Condition_Product,
    query,
  };
};

export const productByDistance = (latitude,longitude,distance,search,condition) => {
  return {
    type: Get_Distance_Product,
    latitude,
    longitude,
    distance,
    search,
    condition
  };
};

export const editProduct = (data) => {
  return {
    type: Edit_Product,
    data,
  };
};

export const emtiEditProduct = () => {
  return {
    type: Empty_Edit_Product,
  };
};
