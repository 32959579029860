
//filter
export const Category_Product = 'Category_Product'
export const Condition_Product = 'Condition_Product'
export const Set_Product_List = 'Set_Product_List'

//product
export const Get_Distance_Product = "Get_Distance_Product"
export const Set_Distance_Product = "Set_Distance_Product"

//EditProduct
export const Edit_Product = "Edit_Product"
export const Empty_Edit_Product = "Empty_Edit_Product"