import React, { useState, useEffect } from "react";
import Bannerimg from "../../image/banner.png";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";

const Banner = () => {
  const [progress, setProgress] = useState(0);
  const [getimg, setgetimg] = useState(null);

  useEffect(() => {
    const handleBanner = async () => {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
              process.env.REACT_APP_API_BANNER
          )
          .then((res) => {
            setgetimg(res.data.banner[0].banner_image);
          })
          .catch((error) => {
            if (error.response) {
              // Handle errors with a response (e.g., server errors)
              console.log("Data :", error.response.data);
              toast.warning(error.response.data.message);
              console.log("Status :" + error.response.status);
              setProgress(100);
            } else if (error.request) {
              // Handle errors without a response (e.g., network errors)
              console.log("No response received.");
              toast.error("No response received.");
              setProgress(100);
            } else {
              // Handle other errors (e.g., setup errors)
              console.log("Error", error.message);
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };
    handleBanner();
  }, []);
  console.log("getimg", getimg);
  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="banner-sec">
        <img
        className="lg:pl-12 lg:pr-12 h-[250px] w-full"
          src={getimg}
          alt="Banner-img"
        />
      </div>
    </div>
  );
};

export default Banner;
