import React, { useState, useEffect } from "react";
import Productup from "../image/product_upload.jpg";
// import Dropdown from 'react-bootstrap/Dropdown';
import { Select } from "react-jsx-select";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { toast } from "react-custom-alert";
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { editProduct } from "../redux/productAction";
import { useSelector } from "react-redux";
const EditProduct = () => {

    const data = useSelector((state) => state.EditData);

    // console.log(";;;;;;;;;;;;;;", data[0].product_image)

    const navigate = useNavigate();
    const [title, settitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [zip, setzip] = useState('');
    const [places, setPlaces] = useState('');
    const [category, setCategory] = useState('')
    // const [categoryId, setcategoryId] = useState()
    const [subCategoryinput, setSubcategoryinput] = useState('')
    const [condition, setCondition] = useState('')


    useEffect(() => {
        console.log("LLLLLLLLLLLLL")
        if (data && data.length > 0) {
            console.log(";;;;;;;;;;;;;;", data[0].product_image)
            console.log(">>>>>>>>>>>>>>______________LLLLLLLLL", data[0].product_name)
            settitle(data[0].product_name);
            setDescription(data[0].product_description);
            setPrice(data[0].product_price);
            setzip(data[0].zipcode)
            setPlaces(data[0].city)
            setDistrict(data[0].district)
            setCategory(data[0].country)
            setState(data[0].district)
        }
    }, []);



    const [categoryList, setCategorylist] = useState([]);
    const [subCategory, setsubCategory] = useState([]);
    const [conditions, setconditions] = useState([])
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const handleCategories = async () => {
            try {
                await axios
                    .get(
                        process.env.REACT_APP_API_BASE_URL +
                        process.env.REACT_APP_API_CATEGORIES
                    )
                    .then((res) => {
                        setCategorylist(res.data.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Handle errors with a response (e.g., server errors)
                            console.log("Data :", error.response.data);
                            toast.warning(error.response.data.message);
                            console.log("Status :" + error.response.status);
                            setProgress(100);
                        } else if (error.request) {
                            // Handle errors without a response (e.g., network errors)
                            console.log("No response received.");
                            toast.error("No response received.");
                            setProgress(100);
                        } else {
                            // Handle other errors (e.g., setup errors)
                            console.log("Error", error.message);
                            toast.error(error.message);
                            setProgress(100);
                        }
                    });
            } catch (err) {
                toast.error("Something Wrong");
            }
        };



        const handleconditions = async () => {
            try {
                await axios
                    .get(
                        process.env.REACT_APP_API_BASE_URL +
                        process.env.REACT_APP_API_CONDITIONS
                    )
                    .then((res) => {
                        setconditions(res.data.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Handle errors with a response (e.g., server errors)
                            console.log("Data :", error.response.data);
                            toast.warning(error.response.data.message);
                            console.log("Status :" + error.response.status);
                            setProgress(100);
                        } else if (error.request) {
                            // Handle errors without a response (e.g., network errors)
                            console.log("No response received.");
                            toast.error("No response received.");
                            setProgress(100);
                        } else {
                            // Handle other errors (e.g., setup errors)
                            console.log("Error", error.message);
                            toast.error(error.message);
                            setProgress(100);
                        }
                    });
            } catch (err) {
                toast.error("Something Wrong");
            }
        };

        handleconditions()
        handleCategories();

    }, []);

    console.log("tttttt", categoryList)
    console.log("tttuuuu", subCategory.chield)


    const handleCategoryChange = (value) => {
        console.log(value)
        const selectedCategory = value ? value.value : null;
        setCategory(selectedCategory);

    };

    useEffect(() => {
        const handleSubCategories = async () => {
            try {
                await axios
                    .post(
                        "https://justfree.webinatech.com/admin/public/api/subcategory", {
                        category_id: category
                    }
                    )
                    .then((res) => {
                        setsubCategory(res.data.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Handle errors with a response (e.g., server errors)
                            console.log("Data :", error.response.data);
                            toast.warning(error.response.data.message);
                            console.log("Status :" + error.response.status);
                            setProgress(100);
                        } else if (error.request) {
                            // Handle errors without a response (e.g., network errors)
                            console.log("No response received.");
                            toast.error("No response received.");
                            setProgress(100);
                        } else {
                            // Handle other errors (e.g., setup errors)
                            console.log("Error", error.message);
                            toast.error(error.message);
                            setProgress(100);
                        }
                    });
            } catch (err) {
                toast.error("Something Wrong");
            }
        };
        handleSubCategories();
    }, [category])


    console.log("ggg", category)

    const handleSubCategoryChange = (value) => {
        console.log(value, "rrrrrrrrrrrryyyyyyyyyyysssssssssss=======>>>>>")
        const selectedCategory = value ? value.value : null;
        setSubcategoryinput(selectedCategory);
    };
    const handleSubConditionChange = (value) => {
        const selectedCategory = value ? value.label : null;
        setCondition(selectedCategory);
    };

    const [selectedImages, setSelectedImages] = useState([null, null, null, null]);

    useEffect(() => {
        console.log("LLLLLLLLLLLLL");
        if (data && data.length > 0) {
            const productImages = data[0].product_image;
            if (productImages && Array.isArray(productImages)) {
                const updatedImages = productImages.map(item => item ? item.image : null);
                console.log(updatedImages, "=============<<<<>>>>>>>>LLLLLLLLYYYYYYYYYY", updatedImages.length > 4)
                if (updatedImages.length < 4) {
                    const remainingNulls = 4 - updatedImages.length;
                    const newImages = updatedImages.concat(Array(remainingNulls).fill(null));
                    setSelectedImages(newImages);
                } else {
                    setSelectedImages(updatedImages.slice(0, 4)); // Only take the first 4 images
                }
            }
        }
    }, [data]);



    const [img, setimg] = useState([])
    useEffect(() => {
        setimg(selectedImages.slice(0, 4));
    }, [selectedImages]);



    const fileInputRef = React.createRef();

    const handleFileChange = (index, e) => {
        const file = e.target.files[0];

        setimg((prevImg) => [...prevImg, file]);

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const newImages = [...selectedImages];
                newImages[index] = reader.result; // Use reader.result instead of e.target.result
                setSelectedImages(newImages);
            };

            reader.readAsDataURL(file);
        }
    };

    console.log(subCategoryinput, "subCategoryinput===>>>>>>>>>>>>>>KKKKKKKKKK", img)

    console.log("tttttttttttttttttttttttttttttttttttttjjjjjjjjjjjjjjjjjjjjj??????????", img);


    const handleForm = async (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        const token = JSON.parse(localStorage.getItem("just_free_token"));
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("product_name", title);
        formdata.append("product_category", subCategoryinput);
        formdata.append("product_condition", condition);
        formdata.append("longitude", localStorage.getItem("longitude"));
        formdata.append("latitude", localStorage.getItem("latitude"));

        img.forEach((item) => {
            if (typeof item === 'string') {
                // Handle string URLs or file paths
                if (item.startsWith('http') || item.startsWith('https')) {
                    const parts = item.split('/');
                    const filename = parts[parts.length - 1];
                    formdata.append('image_name[]', filename);
                } else {
                    // // Assuming item is a file path here
                    // console.log("gggggggg");
                    // const file = new File([item], item.split('/').pop(), { type: 'image/png' });
                    // formdata.append('product_image[]', file, file.name);

                    formdata.append('product_image[]', item,);

                }
            } else if (item instanceof File) {
                // Handle File objects
                console.log("fffffffff");
                formdata.append('product_image[]', item, item.name);
            }
        });




        formdata.append("zipcode", zip);
        formdata.append("city", places);
        formdata.append("district", district);
        formdata.append("country", state);
        formdata.append("product_description", description);
        formdata.append("product_price", price);
        formdata.append("product_id", data[0].product_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        try {
            const response = await fetch(
                "https://justfree.webinatech.com/admin/public/api/product-update",
                requestOptions
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            toast.success(responseData.message);
            setPrice('');
            settitle('');
            setPlaces('');
            setDescription('');
            navigate('/');
        } catch (error) {
            console.error('error', error);
        }
    };



    return (
        <section className=" bg-white">
            <LoadingBar
                color="#FF8000"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        {/* <p className="max-w-xl text-lg">
              Introducing the "SmileRadiance" teeth-whitening kit, your ticket
              to a brighter, more confident smile. Experience the power of
              professional-grade whitening from the comfort of your home. Our
              easy-to-use kit includes all the essentials for a dazzling
              transformation.
            </p> */}

                        <div className="mt-8">
                            <img className="pr-5" src={Productup} alt="Banner-img" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <form action="" className="space-y-2">
                            <div>
                                <label className="sr-only" htmlFor="name">
                                    Title
                                </label>
                                <input
                                    className="w-full rounded-lg border-gray-900 p-3 text-sm text-black placeholder-gray-900 "
                                    placeholder="Title (Max 30 Word)"
                                    type="text"

                                    value={title}
                                    onChange={(e) => settitle(e.target.value)}
                                />
                            </div>

                            <div>
                                <label className="sr-only" htmlFor="message">
                                    Description
                                </label>

                                <textarea
                                    className="w-full rounded-lg border-gray-200 p-3 text-sm text-black placeholder-gray-900"
                                    placeholder="Description"
                                    rows="8"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}

                                ></textarea>
                            </div>

                            <div className="grid grid-cols-1 gap-3 sm:grid-cols-1">
                                <div>
                                    <label className="sr-only" htmlFor="email">
                                        price
                                    </label>
                                    <input
                                        className="w-full rounded-lg border-gray-200 p-3 text-sm text-black placeholder-gray-900"
                                        placeholder="price"
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}

                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 gap-1 text-center sm:grid-cols-1">
                                <div>
                                    <Select
                                        options={categoryList.map((value, index) => (
                                            { value: value.id, label: value.category_name, jsx: <span key={index}>{value.category_name}</span> }
                                        ))}
                                        onChange={(value) => handleCategoryChange(value)}
                                        placeholder="Category"
                                        className="placeholder-gray-900"
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 gap-1 text-center sm:grid-cols-1">
                                <div>
                                    <Select
                                        options={subCategory.map((childCategory) => ({
                                            value: childCategory.id,
                                            label: childCategory.category_name,
                                            jsx: <span key={childCategory.id}>{childCategory.category_name}</span>,
                                        }))}
                                        onChange={(value) => handleSubCategoryChange(value)}
                                        placeholder="Sub-category"
                                        className="placeholder-gray-900"
                                    />


                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-1 text-center sm:grid-cols-1">
                                <div>
                                    <Select
                                        options={conditions.map((value, index) => (
                                            { value: value.condition_name, label: value.condition_name, jsx: <span key={index}>{value.condition_name}</span> }
                                        ))}
                                        onChange={(value) => handleSubConditionChange(value)}
                                        placeholder="Condition"
                                        className="placeholder-gray-900"
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                                <div>
                                    <label className="sr-only" htmlFor="email">
                                        State/Select
                                    </label>
                                    <input
                                        className="w-full rounded-lg border-gray-200 p-3 text-sm text-black placeholder-gray-900"
                                        placeholder="State/Select"
                                        type="text"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        id="State"
                                    />
                                </div>

                                <div>
                                    <label className="sr-only" htmlFor="phone">
                                        District/Select
                                    </label>
                                    <input
                                        className="w-full rounded-lg border-gray-200 p-3 text-sm text-black placeholder-gray-900"
                                        placeholder="District/Select"
                                        type="text"
                                        value={district}
                                        onChange={(e) => setDistrict(e.target.value)}
                                        id="District"
                                    />
                                </div>
                                <div>
                                    <label className="sr-only" htmlFor="phone">
                                        Places
                                    </label>
                                    <input
                                        className="w-full rounded-lg border-gray-200 p-3 text-sm text-black placeholder-gray-900"
                                        placeholder="Places"
                                        type="text"
                                        value={places}
                                        onChange={(e) => setPlaces(e.target.value)}

                                    />
                                </div>
                                <div>
                                    <label className="sr-only" htmlFor="phone">
                                        Zip Code
                                    </label>
                                    <input
                                        className="w-full rounded-lg border-gray-200 p-3 text-sm text-black placeholder-gray-900"
                                        placeholder="Zip Code"
                                        type="number"
                                        value={zip}
                                        onChange={(e) => setzip(e.target.value)}

                                    />
                                </div>
                            </div>
                            <h5 className="">Uplode Image</h5>
                            <div className="grid grid-cols-1 gap-3 sm:grid-cols-4">

                                {selectedImages.map((image, index) => (
                                    <div key={index} className="flex items-center justify-center w-full">

                                        <label
                                            htmlFor={`dropzone-file-${index}`}
                                            className="flex flex-col items-center justify-center w-full h-36 border-2 border-gray-100 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100 "
                                        >
                                            {image ? (
                                                <img src={image} alt={`Selected ${index}`} className="w-36 h-36 object-cover" />
                                            ) : (
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg
                                                        className="w-8 h-8 mb-4 text-gray-500 "
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 20 16"
                                                    >
                                                        <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                            <input
                                                id={`dropzone-file-${index}`}
                                                type="file"
                                                className="hidden"
                                                ref={fileInputRef}
                                                onChange={(e) => handleFileChange(index, e)}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-4">
                                <button
                                    disabled={!title || !category || !condition || !img || !zip || !places || !district || !state || !description || !price}
                                    onClick={handleForm}
                                    type="submit"
                                    className={`inline-block w-full rounded-lg ${!title || !category || !condition || !zip || !places || !district || !state || !description || !price ? " bg-gray-500" : "bg-[#Fa8128] hover:bg-[#c55302]"}  px-5 py-3 font-medium text-white sm:w-auto cursor-pointer `}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditProduct;
