import { combineReducers } from "redux";

import { productData } from "./productReducer";
import {productDistanceData} from "./productDistanceReducer"
import { EditData } from "./editReducer";

export default combineReducers({
  productData,
  productDistanceData,
  EditData
});
